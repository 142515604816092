@import "../../sass/variables";

.product-detail-fourth-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: lighten($navbarMenuColorPrimary, $amount: 60);
  .product-detail-fourth-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin: 4rem 0;
    .pdf-title {
      font-size: 2rem;
      font-weight: 500;
      color: darken($navbarTxtColorPrimary, $amount: 10);
    }
    .pdf-list-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      color: $navbarTxtColorPrimary;
    }
    .pdf-advice-txt {
      color: $navbarTxtColorPrimary;
    }
  }
}
