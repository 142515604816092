@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.navbar-home-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
}
.navbar-home-container .navbar-home-subcontainer {
  width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding: 0 1rem;
  z-index: 99;
}
.navbar-home-container .navbar-home-subcontainer .logo-container {
  width: 8rem;
}
.navbar-home-container .navbar-home-subcontainer .logo {
  width: 100%;
  height: 100%;
}
.navbar-home-container .navbar-home-subcontainer .nh-menus-container {
  display: flex;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
  align-items: center;
}
.navbar-home-container .navbar-home-subcontainer .nh-menu {
  color: #606163;
  text-decoration: none;
}
.navbar-home-container .navbar-home-subcontainer .nh-menu:hover {
  color: #0089d1;
  border-bottom: 2px solid #0089d1;
}
.navbar-home-container .navbar-home-subcontainer .job-offer-btn {
  background-color: #5fff00;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
}
.navbar-home-container .navbar-home-subcontainer .job-offer-btn:hover {
  color: #5fff00;
  background-color: #ffffff;
  border: 1px solid #5fff00;
}
.navbar-home-container .navbar-home-subcontainer .ham-menu-container {
  display: none;
}
.navbar-home-container .navbar-home-subcontainer .ham-menu-icon {
  width: 1rem;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container {
  display: none;
  position: absolute;
  top: 5rem;
  width: 100%;
  background-color: #ffffff;
  left: -40rem;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container .anchor-container {
  padding: 1rem 0 1rem 2rem;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container .anchor-container:hover {
  color: #ffffff;
  background-color: #55595c;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container .anchor-container:hover .anchor {
  color: #ffffff;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container .anchor {
  color: #000000;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container .anchor:link {
  color: #000000;
  text-decoration: none;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container .anchor:visited {
  color: #000000;
}
.navbar-home-container .navbar-home-subcontainer .menu-options-container .anchor:active {
  color: #000000;
}
.navbar-home-container .navbar-home-subcontainer .open-menu {
  animation-name: openMenuNv;
  animation-duration: 1s;
  left: 0rem;
}
@keyframes openMenuNv {
  0% {
    left: -70rem;
  }
  100% {
    left: 0rem;
  }
}
.navbar-home-container .navbar-home-subcontainer .close-menu {
  animation-name: closeMenuNv;
  animation-duration: 1s;
  left: -70rem;
}
@keyframes closeMenuNv {
  0% {
    left: 0rem;
  }
  100% {
    left: -70rem;
  }
}
.navbar-home-container .navbar-home-subcontainer .ham-menu-icon {
  width: 1.5rem;
  height: 100%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .logo-container {
    width: 6rem !important;
  }
  .nh-menus-container {
    display: none !important;
  }
  .ham-menu-container {
    display: unset !important;
  }
  .menu-options-container {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .logo-container {
    width: 6rem !important;
  }
  .nh-menus-container {
    display: none !important;
  }
  .ham-menu-container {
    display: unset !important;
  }
  .menu-options-container {
    display: unset !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar-home-subcontainer {
    padding: 0 10% !important;
  }
  .nh-menus-container {
    display: none !important;
  }
  .ham-menu-container {
    display: unset !important;
  }
  .menu-options-container {
    display: unset !important;
  }
}/*# sourceMappingURL=NavbarHome.css.map */