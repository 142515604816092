@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.prizes-carousel-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}
.prizes-carousel-section .prizes-carousel-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3rem;
  margin: 4rem 0;
}
.prizes-carousel-section .prizes-carousel-subcontainer .pc-carousel-container {
  width: 100%;
}
.prizes-carousel-section .prizes-carousel-subcontainer .pc-title {
  color: #4a4a4a;
  font-size: 1.3rem;
  text-align: center;
}
.prizes-carousel-section .prizes-carousel-subcontainer .pc-carousel-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.prizes-carousel-section .prizes-carousel-subcontainer .pcci-subtitle {
  color: #4a4a4a;
  font-size: 1.1rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .pc-title {
    font-size: 1rem !important;
  }
  .pcci-subtitle {
    text-align: center;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .pc-title {
    font-size: 1rem !important;
  }
  .pcci-subtitle {
    text-align: center;
  }
}/*# sourceMappingURL=PrizesCarouselSection.css.map */