@import "../../sass/variables";

.team-people-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .team-people-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
    row-gap: 2rem;
    .tp-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .tp-content-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
    }
    .tp-person-card-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
    .tp-pc-img {
      width: 100%;
      height: auto;
    }
    .tp-pc-txt-container {
      text-align: center;
      border: 1px solid $mainColorPrimary;
      background-color: $mainColorPrimary;
      color: $basicColorPrimary;
      border-radius: 1rem;
      padding: 0.5rem 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
    }
    .tp-pc-txt-title {
      font-size: 1rem;
    }
    .tp-pc-txt-subtitle {
      font-size: 0.8rem;
    }
    .tp-content-container-mobile {
      width: 100%;
      display: none;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .team-people-subcontainer {
    width: 90% !important;
    .tp-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .tp-content-container {
      display: none !important;
    }
    .tp-content-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .team-people-subcontainer {
    width: 90% !important;
    .tp-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .tp-content-container {
      display: none !important;
    }
    .tp-content-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .team-people-subcontainer {
    width: 90% !important;
    .tp-title {
      text-align: center;
    }
  }
}