@import "../../sass/variables";

.product-intro-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .product-intro-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    margin: 4rem 2%;
    .pi-title {
      font-size: 2rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
    }
    .pi-content-container {
      display: flex;
      align-items: center;
      column-gap: 2rem;
    }
    .pi-content-txt-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 3rem;
    }
    .pi-content-txt-subcontainer {
      color: darken($navbarMenuColorPrimary, $amount: 0);
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pi-content-img {
      width: 35rem;
      height: auto;
    }
    .pi-content-btn {
      background-color: #009add;
      border: 1px solid #009add;
      color: white;
      padding: 0.5rem 1.5rem;
      border-radius: 1rem;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background-color: white;
        color: #009add;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-intro-subcontainer {
    width: 100% !important;
    .pi-content-container {
      flex-direction: column;
      align-items: center;
    }
    .pi-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .pi-content-img {
      width: 100% !important;
    }
    .pi-content-container {
      row-gap: 1.5rem;
    }
    .pi-content-btn-container {
      margin-top: 1rem;
    }
    .pi-content-txt-subcontainer {
      text-align: center;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-intro-subcontainer {
    width: 80% !important;
    .pi-content-container {
      flex-direction: column;
      align-items: center;
    }
    .pi-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .pi-content-img {
      width: 100% !important;
    }
    .pi-content-container {
      row-gap: 1.5rem;
    }
    .pi-content-btn-container {
      margin-top: 1rem;
    }
    .pi-content-txt-subcontainer {
      text-align: center;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .pi-content-img {
    width: 30rem !important;
  }
  .pi-content-btn-container {
    margin-top: 1rem;
  }
}
