@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");

/*Colors*/

$basicColorPrimary: #ffffff;
$basicColorSecondary: #000000;
$navbarTxtColorPrimary: #606163;
$navbarBtnColorPrimary: #5fff00;
$mainColorPrimary: #0089d1;
$mainBgColor: #eaeff2;
$homeCompanyBtnPrimary: #e4a442;
$navbarMenuColorPrimary: #55595c;
$adviceSectionBg: #ffb400;

/*Colors Transparency*/

/*Hover Colors*/

/*Button Color Effect*/

/*Styles*/

* {
  margin: 0;
}

/*Responsive - breakpoints*/
$mobileSm: 320px;
$mobileStandard: 480px;
$tabletSm: 768px;
$tabletStandard: 1024px;
$screenSm: 1200px;
$screenHd: 1920px;
