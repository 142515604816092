@import "../../sass/variables";

.product-tab-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .product-tab-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 4rem 2%;
    row-gap: 3rem;
    .pt-title {
      font-size: 2rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
    }
    .pt-tab-container {
      width: 70%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-tab-subcontainer {
    width: 100% !important;
    .pt-title {
      font-size: 1.5rem !important;
    }
    .pt-tab-container {
      width: 100% !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-tab-subcontainer {
    width: 80% !important;
    .pt-title {
      font-size: 1.5rem !important;
    }
    .pt-tab-container {
      width: 100% !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .product-tab-subcontainer {
    width: 80% !important;
    .pt-title {
      font-size: 1.5rem !important;
    }
    .pt-tab-container {
      width: 100% !important;
    }
  }
}
