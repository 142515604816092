@import "../../sass/variables";

.insurance-company-grid-section {
  width: 100%;
  background-color: $mainColorPrimary;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .insurance-company-grid-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    .icg-title {
      font-size: 2rem;
      font-weight: 400;
      color: $basicColorPrimary;
    }
    .icg-grid-component-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12rem;
    }
    .icg-grid-component-mobile-container {
      display: none;
      min-height: 20rem;
    }
  }
  .section-skew {
    clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
    width: 100%;
    height: 36rem;
    background-color: $basicColorPrimary;
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid $basicColorPrimary;
  }
}

//Responsive Mobile Small - Mobile Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .insurance-company-grid-subcontainer {
    width: 100% !important;
    margin-top: 2rem !important;
    .icg-title {
      font-size: 1.5rem !important;
      text-align: center !important;
    }
    .icg-grid-component-container {
      display: none !important;
    }
    .icg-grid-component-mobile-container {
      display: unset !important;
    }
  }
  .section-skew {
    bottom: -1px !important;
  }
}
