@import "../../sass/variables";

.about-us-department-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: lighten($navbarMenuColorPrimary, $amount: 60);
  .about-us-department-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
    row-gap: 2rem;
    .aud-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .aud-content-container {
      display: flex;
      align-items: flex-end;
      color: $mainColorPrimary;
      width: 100%;
      justify-content: space-between;
    }
    .aud-c-departments-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.1rem;
    }
    .aud-c-d-deco-img {
      width: 6rem;
      height: auto;
      align-self: flex-end;
      margin-bottom: 0.5rem;
    }
    .aud-c-d-second-deco-img {
      margin-top: 0.5rem;
    }
    .aud-c-dm-img {
      width: 100%;
      height: auto;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .about-us-department-subcontainer {
    width: 90% !important;
    .aud-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .aud-content-container {
      flex-direction: column;
      row-gap: 2rem;
      align-items: center !important;
    }
    .aud-c-departments-container {
      align-items: center;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .about-us-department-subcontainer {
    width: 90% !important;
    .aud-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .aud-content-container {
      flex-direction: column;
      row-gap: 2rem;
      align-items: center !important;
    }
    .aud-c-departments-container {
      align-items: center;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .about-us-department-subcontainer {
    width: 90% !important;
    .aud-title {
      text-align: center;
    }
  }
}
