@import "../../sass/variables";

.banner-with-skew-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .banner-with-skew-subcontainer {
    width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .text-slide {
      width: 100%;
      position: relative;
    }
    .ts-info-content {
      position: absolute;
      top: 30%;
      left: 5%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .ts-info-title {
      font-size: 4rem;
      color: $basicColorPrimary;
      font-weight: 500;
    }
    .ts-info-subtitle {
      font-size: 1.5rem;
      color: $basicColorPrimary;
      border: 1px solid $basicColorPrimary;
      padding: 0.25rem 0.25rem;
      text-align: center;
    }
    .ts-info-btn {
      background-color: $basicColorPrimary;
      color: $basicColorSecondary;
      border: 1px solid $basicColorPrimary;
      font-size: 1.25rem;
      width: 10rem;
      padding: 0.25rem 0;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      &:hover {
        background-color: transparent;
        color: $basicColorPrimary;
      }
    }
  }
  .bws-skew-elm {
    clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
    background-color: $mainColorPrimary;
    width: 100%;
    height: 36rem;
    position: absolute;
  }
}

//Responsive Mobile Small - Mobile Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .bws-skew-elm {
    height: 18rem !important;
  }
}