@import "../../sass/variables";

.company-home-second-section {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // top: -22rem;
  .company-home-second-subcontainer {
    position: absolute;
    width: 1024px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 45rem;
    row-gap: 30rem;
    .chs-first-subsection {
      width: 100%;
    }
    .chs-fs-subcontainer {
      max-width: 35rem;
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      text-align: justify;
    }
    .chs-second-subsection {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    .right-pos {
      margin-right: 5%;
    }
    .chs-fs-title {
      font-weight: 400;
      font-size: 2rem;
      color: $navbarMenuColorPrimary;
    }
    .chs-fs-content-txt {
      color: $navbarMenuColorPrimary;
    }
    .white {
      color: $basicColorPrimary;
    }
    .chs-fs-btn-container {
      width: 100%;
    }
    .chs-fs-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      border: 1px solid $mainColorPrimary;
      width: 8rem;
      padding: 0.25rem 0;
      border-radius: 1rem;
      align-items: center;
      background-color: $mainColorPrimary;
      text-decoration: none;
      color: $basicColorPrimary;
      &:hover {
        color: $mainColorPrimary;
        background-color: $basicColorPrimary;
      }
    }
    .chs-fs-btn-icon {
      width: 0.8rem;
      height: auto;
    }
  }
  .chs-img-container {
    width: 1920px;
    margin: 0 auto;
    .chs-img-bg {
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }
  .skew-bottom {
    clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
    background-color: $adviceSectionBg;
    width: 100%;
    height: 36rem;
    position: absolute;
    bottom: 0;
  }
}

//Responsive Mobile Small - Mobile Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .company-home-second-subcontainer {
    width: 90% !important;
    margin-top: 20rem !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .company-home-second-subcontainer {
    width: 80% !important;
    row-gap: 31rem !important;
    margin-top: 20rem !important;
  }
}
