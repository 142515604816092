@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.about-us-culture-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.about-us-culture-section .about-us-culture-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;
  row-gap: 2rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-title {
  color: #3d3f41;
  font-size: 2rem;
  font-weight: 500;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-icons-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-i-img {
  width: 6rem;
  height: auto;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-icon-info-container {
  border: 1px solid #55595c;
  border-radius: 1rem;
  max-width: 40rem;
  height: 0rem;
  opacity: 0;
  transition: 1s ease;
}
.about-us-culture-section .about-us-culture-subcontainer .show-icon-info {
  height: 100%;
  opacity: 1;
}
.about-us-culture-section .about-us-culture-subcontainer .cursor {
  cursor: pointer;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-ii-subcontainer {
  margin: 1rem;
  color: #3d3f41;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-ii-title {
  font-size: 1.1rem;
  text-align: center;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-download-brochure-container {
  margin-top: 4rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-db-btn {
  background-color: #0089d1;
  border: 1px solid #0089d1;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-db-btn:hover {
  background-color: #ffffff;
  color: #0089d1;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-img-txt-container {
  border: 1px solid #55595c;
  border-radius: 1rem;
  width: 90%;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-img-txt {
  margin: 1rem;
  color: #3d3f41;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.about-us-culture-section .about-us-culture-subcontainer .auce-content-container-mobile {
  display: none;
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .about-us-culture-subcontainer {
    width: 90% !important;
  }
  .about-us-culture-subcontainer .auce-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .about-us-culture-subcontainer .auce-content-container {
    display: none !important;
  }
  .about-us-culture-subcontainer .auce-content-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .about-us-culture-subcontainer {
    width: 90% !important;
  }
  .about-us-culture-subcontainer .auce-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .about-us-culture-subcontainer .auce-content-container {
    display: none !important;
  }
  .about-us-culture-subcontainer .auce-content-container-mobile {
    display: unset !important;
  }
}/*# sourceMappingURL=AboutUsCultureSection.css.map */