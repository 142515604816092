@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.about-us-banner-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.about-us-banner-section .about-us-banner-subcontainer {
  width: 1920px;
  margin: 0 auto;
  overflow: hidden;
}/*# sourceMappingURL=AboutUsBanner.css.map */