@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-detail-button-info-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}
.product-detail-button-info-component .pdbi-img {
  width: 10rem;
  height: auto;
}
.product-detail-button-info-component .pdbi-title {
  color: #3d3f41;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
}
.product-detail-button-info-component .pdbi-title-btn {
  padding: 0.5rem 2rem;
  background-color: #0089d1;
  border: 1px solid #0089d1;
  color: #ffffff;
  font-weight: bold;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  cursor: pointer;
}
.product-detail-button-info-component .pdbi-title-btn .pdbi-tb-icon {
  width: 1rem;
  height: auto;
}
.product-detail-button-info-component .open {
  background-color: #ffffff;
  color: #0089d1;
}
.product-detail-button-info-component .pdbi-tb-content-container {
  border: 1px solid #d6d8da;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  height: 0rem;
  overflow: hidden;
  opacity: 0;
  transition: 0.25s ease-in;
}
.product-detail-button-info-component .pdbi-tb-content-container .pdbi-tb-title {
  font-size: 1.2rem;
}
.product-detail-button-info-component .pdbi-tb-content-container .pdbi-tb-content {
  font-size: 0.9rem;
}
.product-detail-button-info-component .pdbi-tb-show-content {
  height: 100%;
  opacity: 1;
  transition: 0.25s ease-in;
}/*# sourceMappingURL=ProductDetailButtonInfoComponent.css.map */