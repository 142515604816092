@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-promotion-video-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f2f2;
}
.job-offer-promotion-video-section .job-offer-promotion-video-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  margin: 4rem 0;
}
.job-offer-promotion-video-section .job-offer-promotion-video-subcontainer .jopv-title {
  color: #3d3f41;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.job-offer-promotion-video-section .job-offer-promotion-video-subcontainer .jopv-content-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 4rem;
       column-gap: 4rem;
}
.job-offer-promotion-video-section .job-offer-promotion-video-subcontainer .jopv-video-container {
  width: 25rem;
  height: auto;
}
.job-offer-promotion-video-section .job-offer-promotion-video-subcontainer .jopv-video-txt {
  color: #606163;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  font-size: 1.1rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .job-offer-promotion-video-subcontainer {
    width: 90% !important;
  }
  .job-offer-promotion-video-subcontainer .jopv-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .job-offer-promotion-video-subcontainer .jopv-content-container {
    flex-direction: column;
    row-gap: 3rem;
  }
  .job-offer-promotion-video-subcontainer .jopv-video-container {
    width: 90% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .job-offer-promotion-video-subcontainer {
    width: 80% !important;
  }
  .job-offer-promotion-video-subcontainer .jopv-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .job-offer-promotion-video-subcontainer .jopv-content-container {
    flex-direction: column;
    row-gap: 3rem;
  }
  .job-offer-promotion-video-subcontainer .jopv-video-container {
    width: 90% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job-offer-promotion-video-subcontainer {
    width: 90% !important;
  }
  .job-offer-promotion-video-subcontainer .jopv-title {
    text-align: center;
  }
  .job-offer-promotion-video-subcontainer .jopv-content-container {
    flex-direction: column;
    row-gap: 3rem;
  }
  .job-offer-promotion-video-subcontainer .jopv-video-container {
    width: auto !important;
    height: 50rem !important;
  }
}/*# sourceMappingURL=JobOfferPromotionVideoSection.css.map */