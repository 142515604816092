@import "../../../sass/variables";

.insurance-partner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .insurance-partner-subcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4rem 0;
    row-gap: 4rem;
    .ip-carousel-container {
      width: 100%;
    }
    .ip-title {
      color: #4a4a4a;
      font-size: 1.5rem;
      text-align: center;
    }
    .ipc-item-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .ipc-insurance-logo {
      height: 3rem;
      max-width: 5rem;
    }
  }
}
