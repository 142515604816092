@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.insurance-grid-component-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.insurance-grid-component-container .igc-row-icons {
  display: flex;
  align-items: center;
}
.insurance-grid-component-container .igc-row-icons .igc-icon-btn {
  border: 1px solid #c7c7c8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  flex-direction: column;
  text-decoration: none;
  color: gray;
}
.insurance-grid-component-container .igc-row-icons .igc-icon-btn:hover {
  border-color: #0089d1;
  background-color: #0089d1;
  transition: 0.5s ease 0s;
  color: white;
}
.insurance-grid-component-container .igc-row-icons .line-bottom {
  border-bottom: 0px;
}
.insurance-grid-component-container .igc-row-icons .line-left {
  border-left: 0px;
}
.insurance-grid-component-container .igc-row-icons .igc-icon {
  width: auto;
  height: 4rem;
}
.insurance-grid-component-container .igc-row-icons .igc-subtitle {
  font-size: 0.8rem;
}/*# sourceMappingURL=InsurancesGridComponent.css.map */