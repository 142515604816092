@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.insurance-company-grid-elm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  text-decoration: none;
}
.insurance-company-grid-elm-container .icg-elm-title {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 300;
}
.insurance-company-grid-elm-container .top {
  margin-top: 2rem;
}
.insurance-company-grid-elm-container .bottom {
  margin-bottom: 2rem;
}
.insurance-company-grid-elm-container .icg-elm-img {
  height: 7rem;
  width: auto;
}/*# sourceMappingURL=InsuranceCompanyGridComponent.css.map */