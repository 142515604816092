@import "../../sass/variables";

.product-detail-third-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .pdt-small-banner {
    height: 142px;
    width: auto;
    object-fit: cover;
  }
  .product-detail-third-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
    row-gap: 2rem;
    .pdt-type-txt-content-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      justify-content: center;
    }
    .pdt-ttc-title {
      align-self: flex-start;
      font-size: 2rem;
      font-weight: 500;
      color: darken($navbarTxtColorPrimary, $amount: 10);
    }
    .pdt-ttc-txt {
      color: darken($navbarTxtColorPrimary, $amount: 20);
    }
    .pdt-ttc-list-elm {
      color: darken($navbarTxtColorPrimary, $amount: 20);
    }
    .pdt-ttc-list-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pdt-type-imgs-content-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      row-gap: 2rem;
    }
    .pdt-tic-three-col {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .pdt-tic-four-col {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .pdt-tic-elm-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
    .pdt-tic-e-img {
      width: 8rem;
      height: auto;
    }
    .pdt-tic-e-title {
      font-weight: 500;
      color: $navbarTxtColorPrimary;
      text-align: center;
    }
    .pdt-advice-txt {
      color: $navbarTxtColorPrimary;
    }
    .pdt-type-imgs-content-container-mobile {
      display: none;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-detail-third-subcontainer {
    width: 90% !important;
    .pdt-type-imgs-content-container {
      display: none !important;
    }
    .pdt-type-imgs-content-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-detail-third-subcontainer {
    width: 80% !important;
    .pdt-type-imgs-content-container {
      display: none !important;
    }
    .pdt-type-imgs-content-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .product-detail-third-subcontainer {
    width: 80% !important;
    .pdt-type-imgs-content-container {
      display: none !important;
    }
    .pdt-type-imgs-content-container-mobile {
      display: unset !important;
    }
  }
}