@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.company-home-counter-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.company-home-counter-section .company-home-counter-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;
  row-gap: 2rem;
}
.company-home-counter-section .company-home-counter-subcontainer .auc-title {
  color: #3d3f41;
  font-size: 2rem;
  font-weight: 500;
}
.company-home-counter-section .company-home-counter-subcontainer .auc-counter-info-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}
.company-home-counter-section .company-home-counter-subcontainer .auc-counter-info-container-mobile {
  display: none;
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .company-home-counter-subcontainer {
    width: 90% !important;
  }
  .company-home-counter-subcontainer .auc-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .company-home-counter-subcontainer .auc-counter-info-container {
    display: none !important;
  }
  .company-home-counter-subcontainer .auc-counter-info-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .company-home-counter-subcontainer {
    width: 90% !important;
  }
  .company-home-counter-subcontainer .auc-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .company-home-counter-subcontainer .auc-counter-info-container {
    display: none !important;
  }
  .company-home-counter-subcontainer .auc-counter-info-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .company-home-counter-subcontainer {
    width: 90% !important;
  }
  .company-home-counter-subcontainer .auc-title {
    text-align: center;
  }
}/*# sourceMappingURL=CompanyHomeCounterSection.css.map */