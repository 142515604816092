@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.static-banner-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.static-banner-container .sb-img {
  width: 1920px;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=StaticBannerComponent.css.map */