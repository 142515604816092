@import "../../sass/variables";

.about-us-banner-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .about-us-banner-subcontainer {
    width: 1920px;
    margin: 0 auto;
    overflow: hidden;
  }
}
