@import "../../sass/variables";

.insurance-grid-mobile-component-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .igmc-icon-btn {
    border: 1px solid $navbarTxtColorPrimary;
    margin-right: -1px;
    margin-bottom: -1px;
    padding: 1rem;
    border: 1px solid lighten($navbarTxtColorPrimary, $amount: 40);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: gray;
    &:hover {
      border-color: $mainColorPrimary;
      background-color: $mainColorPrimary;
      transition: 0.5s ease 0s;
      color: white;
    }
    .igmc-icon {
      width: 4rem;
      height: auto;
    }
    .igmc-subtitle {
      font-size: 0.8rem;
    }
  }
}
