@import "../sass/variables";

.salesforce-bg-container {
    width: 100vw;
    height: 100vh;
}

.sb-img {
    width: 100%;
    height: 100%;
}