@import "../../sass/variables";

.product-detail-button-info-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  .pdbi-img {
    width: 10rem;
    height: auto;
  }
  .pdbi-title {
    color: darken($navbarMenuColorPrimary, $amount: 10);
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
  }
  .pdbi-title-btn {
    padding: 0.5rem 2rem;
    background-color: $mainColorPrimary;
    border: 1px solid $mainColorPrimary;
    color: $basicColorPrimary;
    font-weight: bold;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    cursor: pointer;
    .pdbi-tb-icon {
      width: 1rem;
      height: auto;
    }
  }
  .open {
    background-color: $basicColorPrimary;
    color: $mainColorPrimary;
  }
  .pdbi-tb-content-container {
    border: 1px solid lighten($navbarMenuColorPrimary, $amount: 50);
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    height: 0rem;
    overflow: hidden;
    opacity: 0;
    transition: 0.25s ease-in;
    .pdbi-tb-title {
      font-size: 1.2rem;
    }
    .pdbi-tb-content {
      font-size: 0.9rem;
    }
  }
  .pdbi-tb-show-content {
    height: 100%;
    opacity: 1;
    transition: 0.25s ease-in;
  }
}
