@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-detail-banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.product-detail-banner-section .product-detail-banner-subcontainer {
  height: 560px;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .product-detail-banner-subcontainer {
    height: 272px !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-banner-subcontainer {
    height: 272px !important;
  }
}/*# sourceMappingURL=ProductDetailBannerSection.css.map */