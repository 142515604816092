@import "../../sass/variables";

.contact-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .contact-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
    row-gap: 2rem;
    .c-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .c-contact-info-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
    .c-ci-elm-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
    .c-ci-e-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-weight: 500;
    }
    .c-ci-e-txt {
      color: $navbarMenuColorPrimary;
    }
    .c-contact-info-container-mobile {
      width: 100%;
      display: none;
    }
    .c-ci-e-img {
      height: 6rem;
      width: auto;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .contact-subcontainer {
    width: 100% !important;
    .c-title {
      font-size: 1.5rem !important;
    }
    .c-contact-info-container {
      display: none !important;
    }
    .c-contact-info-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .contact-subcontainer {
    width: 100% !important;
    .c-title {
      font-size: 1.5rem !important;
    }
    .c-contact-info-container {
      display: none !important;
    }
    .c-contact-info-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .contact-subcontainer {
    width: 90% !important;
  }
}
