@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.insurance-people-grid-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.insurance-people-grid-section .insurance-people-grid-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  margin: 6rem 0 4rem 0;
}
.insurance-people-grid-section .insurance-people-grid-subcontainer .ipg-title {
  font-size: 2rem;
  font-weight: 500;
  color: #474849;
}
.insurance-people-grid-section .insurance-people-grid-subcontainer .ipg-text {
  font-size: 1rem;
  color: #797a7d;
}
.insurance-people-grid-section .insurance-people-grid-subcontainer .ipg-insurance-btn-container {
  margin-top: 3rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .insurance-people-grid-subcontainer {
    width: 100% !important;
  }
  .insurance-people-grid-subcontainer .ipg-title {
    text-align: center;
    font-size: 1.5rem !important;
  }
  .insurance-people-grid-subcontainer .ipg-text {
    text-align: center;
  }
  .insurance-people-grid-subcontainer .ipg-insurance-btn-container {
    display: none !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .insurance-people-grid-subcontainer {
    width: 100% !important;
  }
  .insurance-people-grid-subcontainer .ipg-title {
    text-align: center;
    font-size: 1.5rem !important;
  }
  .insurance-people-grid-subcontainer .ipg-text {
    text-align: center;
  }
  .insurance-people-grid-subcontainer .ipg-insurance-btn-container {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .ipg-insurance-mobile-btn-container {
    display: none !important;
  }
}/*# sourceMappingURL=InsurancePeopleGridSection.css.map */