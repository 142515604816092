@import "../../../sass/variables";

.company-promotion-section {
    width: 100%;
    display: flex;
    justify-content: center;
    .company-promotion-subcontainer {
        width: 1024px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 4rem 0;
        .cp-video-container {
            height: 25rem;
        }
        video {
            height: 100%;
            width: auto;
        }
    }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .company-promotion-subcontainer {
        width: 100%;
        .cp-video-container {
            width: 100vw;
            display: flex;
            justify-content: center;
            height: unset !important;
        }
        video {
            width: 90% !important;
            height: auto !important;
        }
    }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
    .company-promotion-subcontainer {
        width: 100%;
        .cp-video-container {
            width: 100vw;
            display: flex;
            justify-content: center;
            height: unset !important;
        }
        video {
            width: 90% !important;
            height: auto !important;
        }
    }
}