@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.navbar-container .navbar-subcontainer {
  width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}
.navbar-container .navbar-subcontainer .nbi-social-media-container {
  display: flex;
  -moz-column-gap: 0.8rem;
       column-gap: 0.8rem;
  align-items: center;
}
.navbar-container .navbar-subcontainer .nbi-sm-icon {
  width: 1.5rem;
}
.navbar-container .navbar-subcontainer .no-apply-border {
  border-bottom: 1px solid transparent;
}
.navbar-container .navbar-subcontainer .no-apply-border:hover {
  border-bottom: 1px solid transparent;
}
.navbar-container .navbar-subcontainer * {
  margin: 0;
  padding: 0;
}
.navbar-container .navbar-subcontainer ul {
  list-style-type: none;
}
.navbar-container .navbar-subcontainer > ul {
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.navbar-container .navbar-subcontainer > ul > li {
  display: inline-block;
  position: relative;
  border-radius: 5px;
  padding: 0 20px;
}
.navbar-container .navbar-subcontainer > ul > li:hover > ul {
  display: block;
}
.navbar-container .navbar-subcontainer > ul > li > ul {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;
}
.navbar-container .navbar-subcontainer > ul > li > ul > li {
  position: relative;
  background-color: #ffffff;
}
.navbar-container .navbar-subcontainer > ul > li > ul > li:hover > ul {
  display: block;
}
.navbar-container .navbar-subcontainer > ul > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 0;
  display: none;
}
.navbar-container .navbar-subcontainer > ul > li > ul > li > ul > li {
  background-color: #ffffff;
}
.navbar-container .navbar-subcontainer > ul > li > ul > li > ul > li > a {
  padding: 0 1rem;
}
.navbar-container .navbar-subcontainer ul li a {
  text-decoration: none;
  display: block;
  color: #606163;
}
.navbar-container .navbar-subcontainer ul li a:hover {
  color: #0089d1;
  border-bottom: 2px solid #0089d1;
}
.navbar-container .navbar-subcontainer .navbar-logo {
  width: 10rem;
  height: 100%;
}
.navbar-container .navbar-subcontainer .n-menu-mobile-container {
  height: unset;
  display: none;
  flex-direction: column;
  position: absolute;
  top: 6rem;
  background-color: #ffffff;
  right: 0;
}
.navbar-container .navbar-subcontainer .n-menu-icon {
  display: none;
}
.navbar-container .navbar-subcontainer .nmm-list-container {
  position: unset;
}
.navbar-container .navbar-subcontainer .nmm-sublist-container {
  right: 100%;
  left: unset;
}
.navbar-container .navbar-subcontainer .show-mb-menu {
  animation-name: openMenuNv;
  animation-duration: 1s;
  right: 0;
}
@keyframes openMenuNv {
  0% {
    display: none;
    right: -100%;
  }
  100% {
    display: flex;
    right: 0;
  }
}
.navbar-container .navbar-subcontainer .hide-mb-menu {
  right: -100%;
  display: none !important;
}

.menu-option-container {
  position: relative;
}
.menu-option-container .product-option-container {
  position: absolute;
  right: 36.5%;
}
.menu-option-container .about-us-option-container {
  position: absolute;
  right: 26.5%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .navbar-subcontainer {
    width: 100% !important;
    margin: 1rem;
  }
  .navbar-subcontainer .n-menus-container {
    display: none;
  }
  .navbar-subcontainer .navbar-logo {
    width: 6rem !important;
    height: auto;
  }
  .navbar-subcontainer .n-menu-icon {
    display: unset !important;
    width: 1rem;
    height: auto;
  }
  .navbar-subcontainer .n-menu-mobile-container {
    display: flex !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .navbar-subcontainer {
    width: 100% !important;
    margin: 1rem;
  }
  .navbar-subcontainer .n-menus-container {
    display: none;
  }
  .navbar-subcontainer .navbar-logo {
    width: 6rem !important;
    height: auto;
  }
  .navbar-subcontainer .n-menu-icon {
    display: unset !important;
    width: 1rem;
    height: auto;
  }
  .navbar-subcontainer .n-menu-mobile-container {
    display: flex !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar-subcontainer {
    width: 100% !important;
    margin: 1rem;
  }
  .navbar-subcontainer .n-menus-container {
    display: none;
  }
  .navbar-subcontainer .navbar-logo {
    width: 6rem !important;
    height: auto;
  }
  .navbar-subcontainer .n-menu-icon {
    display: unset !important;
    width: 1rem;
    height: auto;
  }
  .navbar-subcontainer .n-menu-mobile-container {
    display: flex !important;
  }
}
@media screen and (min-width: 1024px) {
  .n-menu-ham-icon-container {
    display: none;
  }
}/*# sourceMappingURL=Navbar.css.map */