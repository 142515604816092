@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.privacy-policy-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.privacy-policy-section .privacy-policy-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-title {
  font-size: 2.5rem;
  color: #0089d1;
  margin-bottom: 2rem;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-subtitle {
  font-weight: 500;
  font-size: 1.75rem;
  color: #3d3f41;
  margin-bottom: 1.5rem;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-subtitle-small {
  color: #303334;
  align-self: flex-start;
  margin: 3rem 0 1rem 0;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-txt {
  color: #55595c;
  text-align: justify;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-txt .pp-anchor {
  color: #0089d1;
  text-decoration: none;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-txt .pp-anchor:visited {
  color: #0089d1;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-txt .pp-anchor:link {
  color: #0089d1;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-list {
  color: #55595c;
  align-self: flex-start;
  margin-bottom: 1rem;
}
.privacy-policy-section .privacy-policy-subcontainer .pp-list .pp-list-elm {
  margin-top: 0.5rem;
  text-align: justify;
}/*# sourceMappingURL=PrivacyPolicySection.css.map */