@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.about-us-teams-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f2f2;
}
.about-us-teams-section .about-us-teams-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  margin: 4rem 0;
}
.about-us-teams-section .about-us-teams-subcontainer .aut-title {
  color: #3d3f41;
  font-size: 2rem;
  font-weight: 500;
}
.about-us-teams-section .about-us-teams-subcontainer .aut-team-btns-container {
  display: flex;
  align-items: flex-end;
  -moz-column-gap: 4rem;
       column-gap: 4rem;
  row-gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
}
.about-us-teams-section .about-us-teams-subcontainer .aut-tb-elm-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.5rem;
  align-items: center;
}
.about-us-teams-section .about-us-teams-subcontainer .aut-tb-e-img {
  width: 7rem;
  height: auto;
}
.about-us-teams-section .about-us-teams-subcontainer .aut-tb-e-btn {
  background-color: #0089d1;
  border: 1px solid #0089d1;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
}
.about-us-teams-section .about-us-teams-subcontainer .aut-tb-e-btn:hover {
  background-color: #ffffff;
  color: #0089d1;
}
.about-us-teams-section .about-us-teams-subcontainer .aut-team-btns-container-mobile {
  display: none;
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .about-us-teams-subcontainer {
    width: 90% !important;
  }
  .about-us-teams-subcontainer .aut-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .about-us-teams-subcontainer .aut-team-btns-container {
    display: none !important;
  }
  .about-us-teams-subcontainer .aut-team-btns-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .about-us-teams-subcontainer {
    width: 90% !important;
  }
  .about-us-teams-subcontainer .aut-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .about-us-teams-subcontainer .aut-team-btns-container {
    display: none !important;
  }
  .about-us-teams-subcontainer .aut-team-btns-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .about-us-teams-subcontainer {
    width: 90% !important;
  }
  .about-us-teams-subcontainer .aut-title {
    text-align: center;
  }
}/*# sourceMappingURL=AboutUsTeamsSection.css.map */