@import "../../sass/variables";

.about-us-counter-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .about-us-counter-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
    row-gap: 2rem;
    .auc-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .auc-counter-info-container {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      width: 100%;
    }
    .auc-counter-info-container-mobile {
      display: none;
      width: 100%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .about-us-counter-subcontainer {
    width: 90% !important;
    .auc-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .auc-counter-info-container {
      display: none !important;
    }
    .auc-counter-info-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .about-us-counter-subcontainer {
    width: 90% !important;
    .auc-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .auc-counter-info-container {
      display: none !important;
    }
    .auc-counter-info-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .about-us-counter-subcontainer {
    width: 90% !important;
    .auc-title {
      text-align: center;
    }
  }
}
