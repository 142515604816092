@import "../../../sass/variables";

.review-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
  .r-stars-subsection {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    background-color: #f7f7f7;
    padding: 2rem 0;
  }
  .r-stars-container {
    width: 650px;
    position: relative;
    display: flex;
    justify-content: center;
    .r-stars-img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .stars-bg-color {
      background-color: #0097eb;
      height: 7.7rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .r-stars-txt-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f7f7f7;
    padding-bottom: 2rem;
    .r-stars-txt {
      width: 1024px;
      display: flex;
      justify-content: center;
      font-size: 1.1rem;
      color: #7a7a7a;
    }
  }
  .review-subcontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    .r-cards-container {
      width: 1024px;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .r-stars-container {
    width: 100% !important;
    margin: 0 1rem;
  }
  .r-stars-subsection {
    height: 4rem !important;
    .r-stars-img {
      height: 3.5rem !important;
      top: -0.05rem !important;
    }
    .stars-bg-color {
      max-height: 3.4rem !important;
    }
  }
  .r-stars-txt {
    margin: 0 1rem;
    text-align: center;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .r-stars-container {
    width: 100% !important;
    margin: 0 1rem;
  }
  .r-stars-subsection {
    height: 4rem !important;
    .r-stars-img {
      height: 5.5rem !important;
      top: -0.05rem !important;
    }
    .stars-bg-color {
      max-height: 5.4rem !important;
    }
  }
  .r-stars-txt {
    margin: 0 1rem;
    text-align: center;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .r-stars-container {
    width: 100% !important;
    margin: 0 1rem;
  }
  .r-stars-subsection {
    height: 7rem !important;
    .r-stars-img {
      height: 7.5rem !important;
      top: -0.05rem !important;
    }
    .stars-bg-color {
      max-height: 7.4rem !important;
    }
  }
  .r-stars-txt {
    margin: 0 1rem;
    text-align: center;
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .stars-bg-color {
    top: 0.1rem !important;
  }
}
