@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-detail-first-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.product-detail-first-section .product-detail-first-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  row-gap: 3rem;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-first-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  color: #3d3f41;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-fi-title {
  color: #3d3f41;
  font-size: 2rem;
  font-weight: 500;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-fi-content {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-fi-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-second-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-si-title {
  color: #3d3f41;
  font-size: 1.8rem;
  font-weight: 500;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-si-btns-container {
  display: grid;
  justify-content: space-between;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 3rem;
}
.product-detail-first-section .product-detail-first-subcontainer .four-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-third-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  color: #3d3f41;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-ti-title {
  color: #3d3f41;
  font-size: 1.8rem;
  font-weight: 500;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-ti-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.product-detail-first-section .product-detail-first-subcontainer .pdf-si-btns-container-mobile {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .product-detail-first-subcontainer {
    width: 90% !important;
  }
  .product-detail-first-subcontainer .pdf-fi-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-si-btns-container {
    display: none !important;
  }
  .product-detail-first-subcontainer .pdf-si-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-ti-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-si-btns-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-first-subcontainer {
    width: 80% !important;
  }
  .product-detail-first-subcontainer .pdf-fi-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-si-btns-container {
    display: none !important;
  }
  .product-detail-first-subcontainer .pdf-si-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-ti-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-si-btns-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .product-detail-first-subcontainer {
    width: 80% !important;
  }
  .product-detail-first-subcontainer .pdf-fi-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-si-btns-container {
    display: none !important;
  }
  .product-detail-first-subcontainer .pdf-si-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-ti-title {
    font-size: 1.5rem !important;
  }
  .product-detail-first-subcontainer .pdf-si-btns-container-mobile {
    display: unset !important;
  }
}/*# sourceMappingURL=ProductDetailFirstSection.css.map */