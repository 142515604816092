@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-tab-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.product-tab-section .product-tab-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4rem 2%;
  row-gap: 3rem;
}
.product-tab-section .product-tab-subcontainer .pt-title {
  font-size: 2rem;
  color: #3d3f41;
}
.product-tab-section .product-tab-subcontainer .pt-tab-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .product-tab-subcontainer {
    width: 100% !important;
  }
  .product-tab-subcontainer .pt-title {
    font-size: 1.5rem !important;
  }
  .product-tab-subcontainer .pt-tab-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .product-tab-subcontainer {
    width: 80% !important;
  }
  .product-tab-subcontainer .pt-title {
    font-size: 1.5rem !important;
  }
  .product-tab-subcontainer .pt-tab-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .product-tab-subcontainer {
    width: 80% !important;
  }
  .product-tab-subcontainer .pt-title {
    font-size: 1.5rem !important;
  }
  .product-tab-subcontainer .pt-tab-container {
    width: 100% !important;
  }
}/*# sourceMappingURL=ProductTabSection.css.map */