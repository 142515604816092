@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.company-promotion-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.company-promotion-section .company-promotion-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
}
.company-promotion-section .company-promotion-subcontainer .cp-video-container {
  height: 25rem;
}
.company-promotion-section .company-promotion-subcontainer video {
  height: 100%;
  width: auto;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .company-promotion-subcontainer {
    width: 100%;
  }
  .company-promotion-subcontainer .cp-video-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    height: unset !important;
  }
  .company-promotion-subcontainer video {
    width: 90% !important;
    height: auto !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .company-promotion-subcontainer {
    width: 100%;
  }
  .company-promotion-subcontainer .cp-video-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    height: unset !important;
  }
  .company-promotion-subcontainer video {
    width: 90% !important;
    height: auto !important;
  }
}/*# sourceMappingURL=CompanyPromotionSection.css.map */