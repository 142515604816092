@import "../../sass/variables";

.about-us-teams-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: lighten($navbarMenuColorPrimary, $amount: 60);
  .about-us-teams-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    margin: 4rem 0;
    .aut-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .aut-team-btns-container {
      display: flex;
      align-items: flex-end;
      column-gap: 4rem;
      row-gap: 3rem;
      flex-wrap: wrap;
      justify-content: center;
    }
    .aut-tb-elm-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 1.5rem;
      align-items: center;
    }
    .aut-tb-e-img {
      width: 7rem;
      height: auto;
    }
    .aut-tb-e-btn {
      background-color: $mainColorPrimary;
      border: 1px solid $mainColorPrimary;
      color: $basicColorPrimary;
      text-decoration: none;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      border-radius: 1rem;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .aut-team-btns-container-mobile {
      display: none;
      width: 100%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .about-us-teams-subcontainer {
    width: 90% !important;
    .aut-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .aut-team-btns-container {
      display: none !important;
    }
    .aut-team-btns-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .about-us-teams-subcontainer {
    width: 90% !important;
    .aut-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .aut-team-btns-container {
      display: none !important;
    }
    .aut-team-btns-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .about-us-teams-subcontainer {
    width: 90% !important;
    .aut-title {
      text-align: center;
    }
  }
}
