@import "../../sass/variables";

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .navbar-subcontainer {
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    .nbi-social-media-container {
      display: flex;
      column-gap: 0.8rem;
      align-items: center;
    }
    .nbi-sm-icon {
      width: 1.5rem;
    }
    .no-apply-border {
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
    * {
      margin: 0;
      padding: 0;
    }
    ul {
      list-style-type: none;
    }
    > ul {
      height: 60px;
      line-height: 60px;
      text-align: center;
      > li {
        display: inline-block;
        position: relative;
        border-radius: 5px;
        padding: 0 20px;
        &:hover {
          > ul {
            display: block;
          }
        }
        > ul {
          position: absolute;
          top: 100%;
          left: 0;
          display: none;
          width: 100%;
          > li {
            position: relative;
            background-color: $basicColorPrimary;
            &:hover {
              > ul {
                display: block;
              }
            }
            > ul {
              > li {
                > a {
                  padding: 0 1rem;
                }
                background-color: $basicColorPrimary;
              }
              position: absolute;
              left: 100%;
              top: 0;
              display: none;
            }
          }
        }
      }
    }
    ul {
      li {
        a {
          text-decoration: none;
          display: block;
          color: $navbarTxtColorPrimary;
          &:hover {
            color: $mainColorPrimary;
            border-bottom: 2px solid $mainColorPrimary;
          }
        }
      }
    }
    .navbar-logo {
      width: 10rem;
      height: 100%;
    }
    .n-menu-mobile-container {
      height: unset;
      display: none;
      flex-direction: column;
      position: absolute;
      top: 6rem;
      background-color: $basicColorPrimary;
      right: 0;
    }
    .n-menu-icon {
      display: none;
    }
    .nmm-list-container {
      position: unset;
    }
    .nmm-sublist-container {
      right: 100%;
      left: unset;
    }
    .show-mb-menu {
      @keyframes openMenuNv {
        0% {
          display: none;
          right: -100%;
        }
        100% {
          display: flex;
          right: 0;
        }
      }
      animation-name: openMenuNv;
      animation-duration: 1s;
      right: 0;
    }
    .hide-mb-menu {
      right: -100%;
      display: none !important;
    }
  }
}

.menu-option-container {
  position: relative;
  .product-option-container {
    position: absolute;
    right: 36.5%;
  }
  .about-us-option-container {
    position: absolute;
    right: 26.5%;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px )) {
  .navbar-subcontainer {
    width: 100% !important;
    margin: 1rem;
    .n-menus-container {
      display: none;
    }
    .navbar-logo {
      width: 6rem !important;
      height: auto;
    }
    .n-menu-icon {
      display: unset !important;
      width: 1rem;
      height: auto;
    }
    .n-menu-mobile-container {
      display: flex !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px )) {
  .navbar-subcontainer {
    width: 100% !important;
    margin: 1rem;
    .n-menus-container {
      display: none;
    }
    .navbar-logo {
      width: 6rem !important;
      height: auto;
    }
    .n-menu-icon {
      display: unset !important;
      width: 1rem;
      height: auto;
    }
    .n-menu-mobile-container {
      display: flex !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px )) {
  .navbar-subcontainer {
    width: 100% !important;
    margin: 1rem;
    .n-menus-container {
      display: none;
    }
    .navbar-logo {
      width: 6rem !important;
      height: auto;
    }
    .n-menu-icon {
      display: unset !important;
      width: 1rem;
      height: auto;
    }
    .n-menu-mobile-container {
      display: flex !important;
    }
  }
}

//Responsive Tablet Standard >
@media screen and (min-width: $tabletStandard) {
  .n-menu-ham-icon-container {
    display: none;
  }
}
