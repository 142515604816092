@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-banner-form-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 128, 128, 0.445);
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  padding: 1rem 2rem;
  width: 20rem;
  row-gap: 0.5rem;
}
.product-banner-form-container .pbf-title {
  font-size: 1rem;
  color: white;
}
.product-banner-form-container .pbf-input-container {
  width: 100%;
  overflow: hidden;
  border-radius: 1rem;
}
.product-banner-form-container .pbf-input-container .pbf-input {
  width: 100%;
  padding: 0.5rem 1rem;
  outline: none;
}
.product-banner-form-container .error-form {
  border: 1px solid red;
}
.product-banner-form-container .pbf-advice-container {
  display: flex;
  align-items: baseline;
  font-size: 0.7rem;
  color: white;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.product-banner-form-container .pbf-btn {
  background-color: #009add;
  border: 1px solid #009add;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
}
.product-banner-form-container .pbf-btn:hover {
  background-color: white;
  color: #009add;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .product-banner-form-container {
    width: 80% !important;
  }
}/*# sourceMappingURL=ProductBannerFormComponent.css.map */