@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.home-body-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: #eaeff2;
  overflow: hidden;
  background: rgb(234, 239, 242);
  background: linear-gradient(215deg, rgb(234, 239, 242) 0%, rgb(194, 209, 216) 100%);
}
.home-body-container .home-body-subcontainer {
  margin-top: 5rem;
}
.home-body-container .home-body-subcontainer .hb-bg-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.home-body-container .home-body-subcontainer .hb-bg-img {
  position: absolute;
  bottom: -3rem;
  padding-right: 10%;
  max-height: 90%;
}
.home-body-container .home-body-subcontainer .hb-bg-img-mobile {
  display: none;
  width: 100%;
  height: 100%;
}
.home-body-container .home-body-subcontainer .hb-info-container {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-body-container .home-body-subcontainer .hb-info-subcontainer {
  padding: 0 10%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  max-width: 25rem;
}
.home-body-container .home-body-subcontainer .hb-info-title {
  color: #606163;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
}
.home-body-container .home-body-subcontainer .hb-info-content {
  color: #606163;
  font-size: 1.2rem;
}
.home-body-container .home-body-subcontainer .hb-info-btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.home-body-container .home-body-subcontainer .company-info {
  justify-content: flex-end;
}
.home-body-container .home-body-subcontainer .hb-info-btn {
  border: 1px solid #0089d1;
  padding: 0.25rem 1.5rem;
  border-radius: 1rem;
  text-decoration: none;
  color: #0089d1;
  font-weight: 700;
}
.home-body-container .home-body-subcontainer .hb-info-btn:hover {
  background-color: #0089d1;
  color: #ffffff;
}
.home-body-container .home-body-subcontainer .company-btn {
  border: 1px solid #e4a442;
  color: #e4a442;
}
.home-body-container .home-body-subcontainer .company-btn:hover {
  background-color: #e4a442;
  color: #ffffff;
}
.home-body-container .home-body-subcontainer .hb-info-deco-img-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.home-body-container .home-body-subcontainer .company-img-info {
  justify-content: flex-end;
}
.home-body-container .home-body-subcontainer .hb-info-img {
  width: auto;
  height: 7rem;
  margin: 0 5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .home-body-container {
    overflow: unset;
    height: unset;
  }
  .home-body-container .hb-bg-img {
    display: none;
  }
  .home-body-container .hb-bg-img-mobile {
    display: unset !important;
  }
  .home-body-container .hb-info-container {
    flex-direction: column;
    position: absolute;
    top: 5rem;
    row-gap: 60%;
  }
  .home-body-container .hb-info-container .hb-info-deco-img-container {
    display: none;
  }
  .home-body-container .hb-info-container .hb-info-title {
    font-size: 1.2rem;
  }
  .home-body-container .hb-info-container .hb-info-content {
    font-size: 0.8rem;
    width: 90%;
  }
  .home-body-container .mb-view-hbis {
    align-items: flex-end;
  }
  .home-body-container .mb-view-hbis .mb-view-hbic {
    width: 60%;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .home-body-container {
    overflow: unset;
    height: unset;
  }
  .home-body-container .hb-bg-img {
    display: none;
  }
  .home-body-container .hb-bg-img-mobile {
    display: unset !important;
  }
  .home-body-container .hb-info-container {
    flex-direction: column;
    position: absolute;
    top: 15%;
    row-gap: 60%;
  }
  .home-body-container .hb-info-container .hb-info-deco-img-container {
    display: none;
  }
  .home-body-container .hb-info-container .hb-info-title {
    font-size: 1.2rem;
  }
  .home-body-container .hb-info-container .hb-info-content {
    font-size: 0.8rem;
    width: 90%;
  }
  .home-body-container .mb-view-hbis {
    align-items: flex-end;
  }
  .home-body-container .mb-view-hbis .mb-view-hbic {
    width: 60%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .home-body-container {
    overflow: unset;
    height: unset;
  }
  .home-body-container .hb-bg-img {
    display: none;
  }
  .home-body-container .hb-bg-img-mobile {
    display: unset !important;
  }
  .home-body-container .hb-info-container {
    flex-direction: column;
    position: absolute;
    top: 15%;
    row-gap: 100%;
  }
  .home-body-container .hb-info-container .hb-info-deco-img-container {
    display: none;
  }
  .home-body-container .hb-info-container .hb-info-content {
    width: 90%;
  }
  .home-body-container .mb-view-hbis {
    align-items: flex-end;
  }
  .home-body-container .mb-view-hbis .mb-view-hbic {
    width: 60%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .hb-info-content {
    font-size: 0.8rem !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .hb-bg-img {
    bottom: -7rem !important;
    max-height: 100% !important;
  }
  .hb-info-title {
    align-self: flex-start;
  }
  .hb-info-content {
    font-size: 0.8rem !important;
    width: 100% !important;
  }
  .mb-view-hbis {
    align-items: flex-end;
  }
  .mb-view-hbis .mb-view-hbic {
    width: 60%;
  }
}/*# sourceMappingURL=HomeBody.css.map */