@import "../../../sass/variables";

.people-home-banner-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .people-home-banner-subcontainer {
    width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    .text-slide {
      width: 100%;
      position: relative;
    }
    .ts-info-content {
      position: absolute;
      top: 30%;
      left: 5%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .ts-info-title {
      font-size: 4rem;
      color: $basicColorPrimary;
      font-weight: 500;
    }
    .ts-info-subtitle {
      font-size: 1.5rem;
      color: $basicColorPrimary;
      border: 1px solid $basicColorPrimary;
      padding: 0.25rem 0.25rem;
      text-align: center;
    }
    .ts-info-btn {
      background-color: $basicColorPrimary;
      color: $basicColorSecondary;
      border: 1px solid $basicColorPrimary;
      font-size: 1.25rem;
      width: 10rem;
      padding: 0.25rem 0;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      &:hover {
        background-color: transparent;
        color: $basicColorPrimary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .ts-info-content {
    left: 10% !important;
    .ts-info-title {
      font-size: 1.25rem !important;
    }
    .ts-info-subtitle {
      font-size: 0.7rem !important;
    }
    .ts-info-btn {
      font-size: 0.8rem !important;
      width: 7rem !important;
    }
  }
}