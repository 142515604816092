@import "../../../sass/variables";

.home-body-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: $mainBgColor;
  overflow: hidden;
  background: rgb(234, 239, 242);
  background: linear-gradient(
    215deg,
    rgba(234, 239, 242, 1) 0%,
    rgba(194, 209, 216, 1) 100%
  );
  .home-body-subcontainer {
    margin-top: 5rem;
    .hb-bg-img-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
    .hb-bg-img {
      position: absolute;
      bottom: -3rem;
      padding-right: 10%;
      max-height: 90%;
    }
    .hb-bg-img-mobile {
      display: none;
      width: 100%;
      height: 100%;
    }
    .hb-info-container {
      width: 100%;
      height: 75vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .hb-info-subcontainer {
      padding: 0 10%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      max-width: 25rem;
    }
    .hb-info-title {
      color: $navbarTxtColorPrimary;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
    }
    .hb-info-content {
      color: $navbarTxtColorPrimary;
      font-size: 1.2rem;
    }
    .hb-info-btn-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    .company-info {
      justify-content: flex-end;
    }
    .hb-info-btn {
      border: 1px solid $mainColorPrimary;
      padding: 0.25rem 1.5rem;
      border-radius: 1rem;
      text-decoration: none;
      color: $mainColorPrimary;
      font-weight: 700;
      &:hover {
        background-color: $mainColorPrimary;
        color: $basicColorPrimary;
      }
    }
    .company-btn {
      border: 1px solid $homeCompanyBtnPrimary;
      color: $homeCompanyBtnPrimary;
      &:hover {
        background-color: $homeCompanyBtnPrimary;
        color: $basicColorPrimary;
      }
    }
    .hb-info-deco-img-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    .company-img-info {
      justify-content: flex-end;
    }
    .hb-info-img {
      width: auto;
      height: 7rem;
      margin: 0 5rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .home-body-container {
    overflow: unset;
    height: unset;
    .hb-bg-img {
      display: none;
    }
    .hb-bg-img-mobile {
      display: unset !important;
    }
    .hb-info-container {
      flex-direction: column;
      position: absolute;
      top: 5rem;
      row-gap: 60%;
      .hb-info-deco-img-container {
        display: none;
      }
      .hb-info-title {
        font-size: 1.2rem;
      }
      .hb-info-content {
        font-size: 0.8rem;
        width: 90%;
      }
    }
    .mb-view-hbis {
      align-items: flex-end;
      .mb-view-hbic {
        width: 60%;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .home-body-container {
    overflow: unset;
    height: unset;
    .hb-bg-img {
      display: none;
    }
    .hb-bg-img-mobile {
      display: unset !important;
    }
    .hb-info-container {
      flex-direction: column;
      position: absolute;
      top: 15%;
      row-gap: 60%;
      .hb-info-deco-img-container {
        display: none;
      }
      .hb-info-title {
        font-size: 1.2rem;
      }
      .hb-info-content {
        font-size: 0.8rem;
        width: 90%;
      }
    }
    .mb-view-hbis {
      align-items: flex-end;
      .mb-view-hbic {
        width: 60%;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .home-body-container {
    overflow: unset;
    height: unset;
    .hb-bg-img {
      display: none;
    }
    .hb-bg-img-mobile {
      display: unset !important;
    }
    .hb-info-container {
      flex-direction: column;
      position: absolute;
      top: 15%;
      row-gap: 100%;
      .hb-info-deco-img-container {
        display: none;
      }
      .hb-info-content {
        width: 90%;
      }
    }
    .mb-view-hbis {
      align-items: flex-end;
      .mb-view-hbic {
        width: 60%;
      }
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .hb-info-content {
    font-size: 0.8rem !important;
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .hb-bg-img {
    bottom: -7rem !important;
    max-height: 100% !important;
  }
  .hb-info-title {
    align-self: flex-start;
  }
  .hb-info-content {
    font-size: 0.8rem !important;
    width: 100% !important;
  }
  .mb-view-hbis {
    align-items: flex-end;
    .mb-view-hbic {
      width: 60%;
    }
  }
}
