@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.company-home-second-section {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.company-home-second-section .company-home-second-subcontainer {
  position: absolute;
  width: 1024px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 45rem;
  row-gap: 30rem;
}
.company-home-second-section .company-home-second-subcontainer .chs-first-subsection {
  width: 100%;
}
.company-home-second-section .company-home-second-subcontainer .chs-fs-subcontainer {
  max-width: 35rem;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-align: justify;
}
.company-home-second-section .company-home-second-subcontainer .chs-second-subsection {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.company-home-second-section .company-home-second-subcontainer .right-pos {
  margin-right: 5%;
}
.company-home-second-section .company-home-second-subcontainer .chs-fs-title {
  font-weight: 400;
  font-size: 2rem;
  color: #55595c;
}
.company-home-second-section .company-home-second-subcontainer .chs-fs-content-txt {
  color: #55595c;
}
.company-home-second-section .company-home-second-subcontainer .white {
  color: #ffffff;
}
.company-home-second-section .company-home-second-subcontainer .chs-fs-btn-container {
  width: 100%;
}
.company-home-second-section .company-home-second-subcontainer .chs-fs-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  border: 1px solid #0089d1;
  width: 8rem;
  padding: 0.25rem 0;
  border-radius: 1rem;
  align-items: center;
  background-color: #0089d1;
  text-decoration: none;
  color: #ffffff;
}
.company-home-second-section .company-home-second-subcontainer .chs-fs-btn:hover {
  color: #0089d1;
  background-color: #ffffff;
}
.company-home-second-section .company-home-second-subcontainer .chs-fs-btn-icon {
  width: 0.8rem;
  height: auto;
}
.company-home-second-section .chs-img-container {
  width: 1920px;
  margin: 0 auto;
}
.company-home-second-section .chs-img-container .chs-img-bg {
  width: auto;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.company-home-second-section .skew-bottom {
  -webkit-clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
          clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
  background-color: #ffb400;
  width: 100%;
  height: 36rem;
  position: absolute;
  bottom: 0;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .company-home-second-subcontainer {
    width: 90% !important;
    margin-top: 20rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .company-home-second-subcontainer {
    width: 80% !important;
    row-gap: 31rem !important;
    margin-top: 20rem !important;
  }
}/*# sourceMappingURL=CompanyHomeSecondSection.css.map */