@import "../../sass/variables";

.product-tab-container {
  width: 100%;
  .product-tab-header-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    background-color: #f7f9fb;
    padding: 0.5rem 0 0.5rem 10%;
    justify-content: flex-start;
    cursor: pointer;
    .pth-icon {
      width: 1rem;
      height: auto;
    }
    .pth-title {
      font-size: 1.1rem;
      font-weight: lighter;
      color: $navbarMenuColorPrimary;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  .pth-open {
    background-color: #009add;
    .pth-title-open {
      color: white;
    }
  }
  .product-tab-content-container {
    overflow: hidden;
    max-height: 0;
    .ptc-txt {
      margin: 1rem 10%;
      color: $navbarMenuColorPrimary;
    }
  }
  .ptc-close {
    max-height: 0;
    transition: 0.3s ease;
  }
  .ptc-open {
    max-height: 100%;
    transition: 0.3s ease;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .pth-icon {
    width: 1rem !important;
    margin-left: 1rem;
  }
  .pth-title {
    font-size: 0.8rem !important;
  }
  .ptc-txt {
    font-size: 0.8rem !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .pth-icon {
    width: 1rem !important;
    margin-left: 1rem;
  }
  .pth-title {
    font-size: 0.8rem !important;
  }
  .ptc-txt {
    font-size: 0.8rem !important;
  }
}
