@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-detail-second-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f2f2;
}
.product-detail-second-section .product-detail-second-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  row-gap: 2rem;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-more-info-title {
  align-self: flex-start;
  font-size: 2rem;
  font-weight: 500;
  color: #474849;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-more-info-content-container {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: #606163;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-info-title {
  font-size: 2rem;
  font-weight: 500;
  color: #474849;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-i-content-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-i-content-elm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.5rem;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-i-ce-img {
  width: 8rem;
  height: auto;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-i-ce-title {
  font-weight: 500;
  color: #474849;
  width: 15rem;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-btn {
  background-color: #0089d1;
  border: 1px solid #0089d1;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  border-radius: 1.5rem;
  text-decoration: none;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-btn:hover {
  background-color: #ffffff;
  color: #0089d1;
}
.product-detail-second-section .product-detail-second-subcontainer .pds-i-content-container-mobile {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .product-detail-second-subcontainer {
    width: 90% !important;
  }
  .product-detail-second-subcontainer .pds-info-title {
    font-size: 1.5rem !important;
  }
  .product-detail-second-subcontainer .pds-i-content-container {
    display: none !important;
  }
  .product-detail-second-subcontainer .pds-i-content-container-mobile {
    display: unset !important;
    width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-second-subcontainer {
    width: 80% !important;
  }
  .product-detail-second-subcontainer .pds-info-title {
    font-size: 1.5rem !important;
  }
  .product-detail-second-subcontainer .pds-i-content-container {
    display: none !important;
  }
  .product-detail-second-subcontainer .pds-i-content-container-mobile {
    display: unset !important;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .product-detail-second-subcontainer {
    width: 90% !important;
  }
  .product-detail-second-subcontainer .pds-info-title {
    font-size: 1.5rem !important;
  }
}/*# sourceMappingURL=ProductDetailSecondSection.css.map */