@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.people-home-banner-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.people-home-banner-section .people-home-banner-subcontainer {
  width: 1920px;
  margin: 0 auto;
  overflow: hidden;
}
.people-home-banner-section .people-home-banner-subcontainer .text-slide {
  width: 100%;
  position: relative;
}
.people-home-banner-section .people-home-banner-subcontainer .ts-info-content {
  position: absolute;
  top: 30%;
  left: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.people-home-banner-section .people-home-banner-subcontainer .ts-info-title {
  font-size: 4rem;
  color: #ffffff;
  font-weight: 500;
}
.people-home-banner-section .people-home-banner-subcontainer .ts-info-subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0.25rem 0.25rem;
  text-align: center;
}
.people-home-banner-section .people-home-banner-subcontainer .ts-info-btn {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ffffff;
  font-size: 1.25rem;
  width: 10rem;
  padding: 0.25rem 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.people-home-banner-section .people-home-banner-subcontainer .ts-info-btn:hover {
  background-color: transparent;
  color: #ffffff;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .ts-info-content {
    left: 10% !important;
  }
  .ts-info-content .ts-info-title {
    font-size: 1.25rem !important;
  }
  .ts-info-content .ts-info-subtitle {
    font-size: 0.7rem !important;
  }
  .ts-info-content .ts-info-btn {
    font-size: 0.8rem !important;
    width: 7rem !important;
  }
}/*# sourceMappingURL=Banner.css.map */