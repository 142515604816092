@import "../../sass/variables";

.insurance-grid-component-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .igc-row-icons {
    display: flex;
    align-items: center;
    .igc-icon-btn {
      border: 1px solid lighten($navbarTxtColorPrimary, $amount: 40);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 2rem;
      flex-direction: column;
      text-decoration: none;
      color: gray;
      &:hover {
        border-color: $mainColorPrimary;
        background-color: $mainColorPrimary;
        transition: 0.5s ease 0s;
        color: white;
      }
    }
    .line-bottom {
      border-bottom: 0px;
    }
    .line-left {
      border-left: 0px;
    }
    .igc-icon {
      width: auto;
      height: 4rem;
    }
    .igc-subtitle {
      font-size: 0.8rem;
    }
  }
}
