@import "../../sass/variables";

.contact-location-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .contact-location-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 4rem;
  }
}
