@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-tab-container {
  width: 100%;
}
.product-tab-container .product-tab-header-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  background-color: #f7f9fb;
  padding: 0.5rem 0 0.5rem 10%;
  justify-content: flex-start;
  cursor: pointer;
}
.product-tab-container .product-tab-header-container .pth-icon {
  width: 1rem;
  height: auto;
}
.product-tab-container .product-tab-header-container .pth-title {
  font-size: 1.1rem;
  font-weight: lighter;
  color: #55595c;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.product-tab-container .pth-open {
  background-color: #009add;
}
.product-tab-container .pth-open .pth-title-open {
  color: white;
}
.product-tab-container .product-tab-content-container {
  overflow: hidden;
  max-height: 0;
}
.product-tab-container .product-tab-content-container .ptc-txt {
  margin: 1rem 10%;
  color: #55595c;
}
.product-tab-container .ptc-close {
  max-height: 0;
  transition: 0.3s ease;
}
.product-tab-container .ptc-open {
  max-height: 100%;
  transition: 0.3s ease;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .pth-icon {
    width: 1rem !important;
    margin-left: 1rem;
  }
  .pth-title {
    font-size: 0.8rem !important;
  }
  .ptc-txt {
    font-size: 0.8rem !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .pth-icon {
    width: 1rem !important;
    margin-left: 1rem;
  }
  .pth-title {
    font-size: 0.8rem !important;
  }
  .ptc-txt {
    font-size: 0.8rem !important;
  }
}/*# sourceMappingURL=ProductTabComponent.css.map */