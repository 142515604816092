@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.footer-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.footer-section .footer-section-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footer-section .fs-first {
  background-color: #ffb400;
}
.footer-section .fs-second {
  background-color: #848484;
}
.footer-section .fs-third {
  background-color: #c0c0be;
}
.footer-section .fs-social-media-container {
  height: 1.3rem;
}
.footer-section .fs-subcontainer {
  width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-section .fs-subcontainer .fs-contact-info-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  color: white;
}
.footer-section .fs-subcontainer .fs-social-media-container {
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.footer-section .fs-subcontainer .fs-sm-icon {
  width: 1.3rem;
  height: auto;
}
.footer-section .fs-subcontainer .fs-footer-content-container {
  display: flex;
  flex-direction: column;
  color: white;
  row-gap: 0.5rem;
}
.footer-section .fs-subcontainer .fs-fc-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.footer-section .fs-subcontainer .fs-fc-anchor {
  color: white;
  text-decoration: none;
}
.footer-section .fs-subcontainer .fs-fc-anchor:hover {
  color: #0089d1;
}
.footer-section .fs-subcontainer .fs-fc-no-anchor {
  color: white;
  text-decoration: none;
}
.footer-section .fs-subcontainer .fs-copyright {
  color: white;
  margin: 0.5rem 1rem;
}
.footer-section .fss-first {
  margin: 0.5rem 1rem;
}
.footer-section .fss-second {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin: 3rem 1rem;
  align-items: flex-start;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .fs-subcontainer {
    width: 100% !important;
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 0.25rem;
  }
  .fss-second {
    row-gap: 2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .fs-subcontainer {
    width: 100% !important;
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 0.25rem;
  }
  .fss-second {
    row-gap: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .fss-first {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=Footer.css.map */