@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-grid-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.product-grid-section .product-grid-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
  margin: 4rem 2%;
}
.product-grid-section .product-grid-subcontainer .pg-title {
  font-size: 2rem;
  color: #3d3f41;
}
.product-grid-section .product-grid-subcontainer .pg-grid-container {
  display: grid;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  row-gap: 2rem;
  align-items: flex-end;
}
.product-grid-section .product-grid-subcontainer .pg-two-col {
  grid-template-columns: 1fr 1fr;
}
.product-grid-section .product-grid-subcontainer .pg-three-col {
  grid-template-columns: 1fr 1fr 1fr;
}
.product-grid-section .product-grid-subcontainer .pg-four-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.product-grid-section .product-grid-subcontainer .pgg-img {
  width: auto;
  height: 14rem;
}
.product-grid-section .product-grid-subcontainer .pgg-subtitle {
  text-align: center;
  color: #3d3f41;
  font-size: 1.2rem;
  margin-top: 1rem;
}
.product-grid-section .product-grid-subcontainer .pgg-small-subtitle {
  text-align: center;
  color: #55595c;
  font-size: 0.7rem;
}
.product-grid-section .product-grid-subcontainer .pgg-txt-container {
  color: #55595c;
  margin-top: 1rem;
}
.product-grid-section .product-grid-subcontainer .pgg-txt-list-container {
  color: #55595c;
  margin-top: 1rem;
}
.product-grid-section .product-grid-subcontainer .pgg-btn-container {
  text-align: center;
  margin-top: 1rem;
}
.product-grid-section .product-grid-subcontainer .pgg-btn {
  background-color: #009add;
  border: 1px solid #009add;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;
}
.product-grid-section .product-grid-subcontainer .pgg-btn:hover {
  background-color: white;
  color: #009add;
}
.product-grid-section .product-grid-subcontainer .pg-grid-container-mobile {
  min-height: 45rem;
  display: none;
}
.product-grid-section .product-grid-subcontainer .pg-grid-container-tablet {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .pg-grid-container {
    display: none !important;
  }
  .pg-title {
    font-size: 1.5rem !important;
  }
  .pg-grid-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .product-grid-subcontainer {
    width: 80% !important;
  }
  .product-grid-subcontainer .pg-grid-container {
    display: none !important;
  }
  .product-grid-subcontainer .pg-title {
    font-size: 1.5rem !important;
  }
  .product-grid-subcontainer .pg-grid-container-mobile {
    display: unset !important;
    min-height: 100% !important;
  }
}/*# sourceMappingURL=ProductGridSection.css.map */