@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-small-banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.product-small-banner-section .psb-img {
  height: 300px;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-small-banner-section .product-small-banner-subcontainer {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
}
.product-small-banner-section .product-small-banner-subcontainer .psb-grid-container {
  width: 1024px;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}
.product-small-banner-section .product-small-banner-subcontainer .psb-elm-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
}
.product-small-banner-section .product-small-banner-subcontainer .psb-title {
  color: #3a3b3c;
}
.product-small-banner-section .product-small-banner-subcontainer .psb-btn {
  background-color: #009add;
  border: 1px solid #009add;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;
}
.product-small-banner-section .product-small-banner-subcontainer .psb-btn:hover {
  background-color: white;
  color: #009add;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .psb-grid-container {
    flex-direction: column;
    width: 90% !important;
    row-gap: 1rem;
  }
  .psb-grid-container .psb-elm-container {
    row-gap: 1rem !important;
  }
}/*# sourceMappingURL=ProductSmallBannerSection.css.map */