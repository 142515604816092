@import "../../sass/variables";

.product-banner-form-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 128, 128, 0.445);
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  padding: 1rem 2rem;
  width: 20rem;
  row-gap: 0.5rem;
  .pbf-title {
    font-size: 1rem;
    color: white;
  }
  .pbf-input-container {
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;

    .pbf-input {
      width: 100%;
      padding: 0.5rem 1rem;
      outline: none;
    }
  }
  .error-form {
    border: 1px solid red;
  }
  .pbf-advice-container {
    display: flex;
    align-items: baseline;
    font-size: 0.7rem;
    color: white;
    column-gap: 0.5rem;
  }
  .pbf-btn {
    background-color: #009add;
    border: 1px solid #009add;
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: #009add;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-banner-form-container {
    width: 80% !important;
  }
}
