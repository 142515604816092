@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.google-reviews-container {
  width: 100%;
}
.google-reviews-container .google-reviews-subcontainer {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.google-reviews-container .google-reviews-subcontainer .gr-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.75rem;
}
.google-reviews-container .google-reviews-subcontainer .gr-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.google-reviews-container .google-reviews-subcontainer .gr-card-container {
  padding: 1rem;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  max-height: 12rem;
  overflow: hidden;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.google-reviews-container .google-reviews-subcontainer .gr-btn {
  border: 1px solid #0089d1;
  background-color: #0089d1;
  color: #ffffff;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
}
.google-reviews-container .google-reviews-subcontainer .gr-btn:hover {
  background-color: #ffffff;
  color: #0089d1;
}
.google-reviews-container .google-reviews-subcontainer .show-txt-card {
  max-height: 100%;
}
.google-reviews-container .google-reviews-subcontainer .grc-user-data-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.google-reviews-container .google-reviews-subcontainer .grc-user-profile-img {
  width: 2.5rem;
  height: auto;
}
.google-reviews-container .google-reviews-subcontainer .grc-user-name {
  font-size: 0.8rem;
}
.google-reviews-container .google-reviews-subcontainer .grc-review-datetime {
  font-size: 0.7rem;
  color: #4d4d4d;
}
.google-reviews-container .google-reviews-subcontainer .gr-stars-deco {
  width: 7rem;
  height: auto;
}
.google-reviews-container .google-reviews-subcontainer .gr-google-logo {
  width: 7rem;
  height: auto;
}
.google-reviews-container .google-reviews-subcontainer .grc-google-icon {
  width: 1.5rem;
  height: auto;
}
.google-reviews-container .google-reviews-subcontainer .gr-rs-elm-container {
  width: 5rem;
  height: 1rem;
  position: relative;
}
.google-reviews-container .google-reviews-subcontainer .gr-review-stars {
  width: 5rem;
  height: auto;
  position: absolute;
}
.google-reviews-container .google-reviews-subcontainer .stars-bg-color-yellow {
  width: 100%;
  height: 0.96rem;
  background-color: #f6bb06;
}
.google-reviews-container .google-reviews-subcontainer .gr-review-txt-container {
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.google-reviews-container .google-reviews-subcontainer .read-more-btn {
  border: 1px solid transparent;
  background-color: #f4f4f4;
  cursor: pointer;
  color: #4d4d4d;
}
.google-reviews-container .google-reviews-subcontainer .grcm-supercontainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .google-reviews-subcontainer {
    width: 100vw !important;
    flex-direction: column !important;
  }
  .google-reviews-subcontainer .grcm-supercontainer {
    width: 100% !important;
    height: 11rem !important;
  }
  .google-reviews-subcontainer .gr-card-container {
    width: 15rem;
  }
  .google-reviews-subcontainer .stars-bg-color-yellow {
    height: 0.97rem !important;
  }
  .gr-cards-container {
    display: none !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .google-reviews-subcontainer {
    width: 100vw !important;
    flex-direction: column !important;
  }
  .google-reviews-subcontainer .grcm-supercontainer {
    width: 100% !important;
    height: 11rem !important;
  }
  .google-reviews-subcontainer .gr-card-container {
    width: 15rem;
  }
  .google-reviews-subcontainer .stars-bg-color-yellow {
    height: 0.97rem !important;
  }
  .gr-cards-container {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .google-reviews-subcontainer {
    width: 100vw !important;
    flex-direction: column !important;
  }
  .google-reviews-subcontainer .grcm-supercontainer {
    width: 100% !important;
    height: 11rem !important;
  }
  .google-reviews-subcontainer .gr-card-container {
    width: 100%;
  }
  .google-reviews-subcontainer .stars-bg-color-yellow {
    height: 0.97rem !important;
  }
  .gr-cards-container {
    display: none !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .gr-review-stars {
    z-index: 1;
  }
  .stars-bg-color-yellow {
    position: absolute;
    top: 0.02rem;
    height: 0.9rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .gr-cards-mobile-container {
    display: none;
  }
}/*# sourceMappingURL=GoogleReviewCards.css.map */