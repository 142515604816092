@import "../../sass/variables";

.google-reviews-container {
  width: 100%;
  .google-reviews-subcontainer {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    .gr-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 0.75rem;
    }
    .gr-cards-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      column-gap: 1rem;
    }
    .gr-card-container {
      padding: 1rem;
      border: 1px solid #f4f4f4;
      background-color: #f4f4f4;
      max-height: 12rem;
      overflow: hidden;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .gr-btn {
      border: 1px solid $mainColorPrimary;
      background-color: $mainColorPrimary;
      color: $basicColorPrimary;
      text-decoration: none;
      padding: 0.25rem 1rem;
      border-radius: 0.5rem;
      font-weight: 500;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .show-txt-card {
      max-height: 100%;
    }
    .grc-user-data-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      column-gap: 1rem;
    }
    .grc-user-profile-img {
      width: 2.5rem;
      height: auto;
    }
    .grc-user-name {
      font-size: 0.8rem;
    }
    .grc-review-datetime {
      font-size: 0.7rem;
      color: lighten($color: #000000, $amount: 30);
    }
    .gr-stars-deco {
      width: 7rem;
      height: auto;
    }
    .gr-google-logo {
      width: 7rem;
      height: auto;
    }
    .grc-google-icon {
      width: 1.5rem;
      height: auto;
    }
    .gr-rs-elm-container {
      width: 5rem;
      height: 1rem;
      position: relative;
    }
    .gr-review-stars {
      width: 5rem;
      height: auto;
      position: absolute;
    }
    .stars-bg-color-yellow {
      width: 100%;
      height: 0.96rem;
      background-color: #f6bb06;
    }
    .gr-review-txt-container {
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .read-more-btn {
      border: 1px solid transparent;
      background-color: #f4f4f4;
      cursor: pointer;
      color: lighten($color: #000000, $amount: 30);
    }
    .grcm-supercontainer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .google-reviews-subcontainer {
    width: 100vw !important;
    flex-direction: column !important;
    .grcm-supercontainer {
      width: 100% !important;
      height: 11rem !important;
    }
    .gr-card-container {
      width: 15rem;
    }
    .stars-bg-color-yellow {
      height: 0.97rem !important;
    }
  }
  .gr-cards-container {
    display: none !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .google-reviews-subcontainer {
    width: 100vw !important;
    flex-direction: column !important;
    .grcm-supercontainer {
      width: 100% !important;
      height: 11rem !important;
    }
    .gr-card-container {
      width: 15rem;
    }
    .stars-bg-color-yellow {
      height: 0.97rem !important;
    }
  }
  .gr-cards-container {
    display: none !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .google-reviews-subcontainer {
    width: 100vw !important;
    flex-direction: column !important;
    .grcm-supercontainer {
      width: 100% !important;
      height: 11rem !important;
    }
    .gr-card-container {
      width: 100%;
    }
    .stars-bg-color-yellow {
      height: 0.97rem !important;
    }
  }
  .gr-cards-container {
    display: none !important;
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm)) {
  .gr-review-stars {
    z-index: 1;
  }
  .stars-bg-color-yellow {
    position: absolute;
    top: 0.02rem;
    height: 0.9rem !important;
  }
}

//Responsive Tablet Standard >
@media screen and (min-width: $tabletStandard) {
  .gr-cards-mobile-container {
    display: none;
  }
}
