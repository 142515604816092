@import "../../sass/variables";

.privacy-policy-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .privacy-policy-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0;
    .pp-title {
      font-size: 2.5rem;
      color: $mainColorPrimary;
      margin-bottom: 2rem;
    }
    .pp-subtitle {
      font-weight: 500;
      font-size: 1.75rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
      margin-bottom: 1.5rem;
    }
    .pp-subtitle-small {
      color: darken($navbarMenuColorPrimary, $amount: 15);
      align-self: flex-start;
      margin: 3rem 0 1rem 0;
    }
    .pp-txt {
      color: $navbarMenuColorPrimary;
      text-align: justify;
      margin-bottom: 0.5rem;
      align-self: flex-start;
      .pp-anchor {
        color: $mainColorPrimary;
        text-decoration: none;
        &:visited {
          color: $mainColorPrimary;
        }
        &:link {
          color: $mainColorPrimary;
        }
      }
    }
    .pp-list {
      color: $navbarMenuColorPrimary;
      align-self: flex-start;
      margin-bottom: 1rem;
      .pp-list-elm {
        margin-top: 0.5rem;
        text-align: justify;
      }
    }
  }
}
