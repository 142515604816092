@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.about-us-department-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f2f2;
}
.about-us-department-section .about-us-department-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;
  row-gap: 2rem;
}
.about-us-department-section .about-us-department-subcontainer .aud-title {
  color: #3d3f41;
  font-size: 2rem;
  font-weight: 500;
}
.about-us-department-section .about-us-department-subcontainer .aud-content-container {
  display: flex;
  align-items: flex-end;
  color: #0089d1;
  width: 100%;
  justify-content: space-between;
}
.about-us-department-section .about-us-department-subcontainer .aud-c-departments-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.1rem;
}
.about-us-department-section .about-us-department-subcontainer .aud-c-d-deco-img {
  width: 6rem;
  height: auto;
  align-self: flex-end;
  margin-bottom: 0.5rem;
}
.about-us-department-section .about-us-department-subcontainer .aud-c-d-second-deco-img {
  margin-top: 0.5rem;
}
.about-us-department-section .about-us-department-subcontainer .aud-c-dm-img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .about-us-department-subcontainer {
    width: 90% !important;
  }
  .about-us-department-subcontainer .aud-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .about-us-department-subcontainer .aud-content-container {
    flex-direction: column;
    row-gap: 2rem;
    align-items: center !important;
  }
  .about-us-department-subcontainer .aud-c-departments-container {
    align-items: center;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .about-us-department-subcontainer {
    width: 90% !important;
  }
  .about-us-department-subcontainer .aud-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .about-us-department-subcontainer .aud-content-container {
    flex-direction: column;
    row-gap: 2rem;
    align-items: center !important;
  }
  .about-us-department-subcontainer .aud-c-departments-container {
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .about-us-department-subcontainer {
    width: 90% !important;
  }
  .about-us-department-subcontainer .aud-title {
    text-align: center;
  }
}/*# sourceMappingURL=AboutUsDepartmentsSection.css.map */