@import "../../sass/variables";

.footer-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .footer-section-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .fs-first {
    background-color: #ffb400;
  }
  .fs-second {
    background-color: #848484;
  }
  .fs-third {
    background-color: #c0c0be;
  }
  .fs-social-media-container {
    height: 1.3rem;
  }
  .fs-subcontainer {
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fs-contact-info-container {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      color: white;
    }
    .fs-social-media-container {
      display: flex;
      column-gap: 0.5rem;
    }
    .fs-sm-icon {
      width: 1.3rem;
      height: auto;
    }
    .fs-footer-content-container {
      display: flex;
      flex-direction: column;
      color: white;
      row-gap: 0.5rem;
    }
    .fs-fc-title {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .fs-fc-anchor {
      color: white;
      text-decoration: none;
      &:hover {
        color: $mainColorPrimary;
      }
    }
    .fs-fc-no-anchor {
      color: white;
      text-decoration: none;
    }
    .fs-copyright {
      color: white;
      margin: 0.5rem 1rem;
    }
  }
  .fss-first {
    margin: 0.5rem 1rem;
  }
  .fss-second {
    column-gap: 1rem;
    margin: 3rem 1rem;
    align-items: flex-start;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .fs-subcontainer {
    width: 100% !important;
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 0.25rem;
  }
  .fss-second {
    row-gap: 2rem;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .fs-subcontainer {
    width: 100% !important;
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 0.25rem;
  }
  .fss-second {
    row-gap: 2rem;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .fss-first {
    font-size: 0.9rem;
  }
}
