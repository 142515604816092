@import "../../sass/variables";

.insurance-company-grid-elm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  text-decoration: none;
  .icg-elm-title {
    font-size: 1rem;
    color: $basicColorPrimary;
    font-weight: 300;
  }
  .top {
    margin-top: 2rem;
  }
  .bottom {
    margin-bottom: 2rem;
  }
  .icg-elm-img {
    height: 7rem;
    width: auto;
  }
}
