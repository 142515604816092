@import "../../sass/variables";

.product-detail-banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .product-detail-banner-subcontainer {
    height: 560px;
    width: auto;
    object-fit: cover;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-detail-banner-subcontainer {
    height: 272px !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-detail-banner-subcontainer {
    height: 272px !important;
  }
}