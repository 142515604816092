@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-detail-fourth-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f2f2;
}
.product-detail-fourth-section .product-detail-fourth-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin: 4rem 0;
}
.product-detail-fourth-section .product-detail-fourth-subcontainer .pdf-title {
  font-size: 2rem;
  font-weight: 500;
  color: #474849;
}
.product-detail-fourth-section .product-detail-fourth-subcontainer .pdf-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: #606163;
}
.product-detail-fourth-section .product-detail-fourth-subcontainer .pdf-advice-txt {
  color: #606163;
}/*# sourceMappingURL=ProductDetailFourthSection.css.map */