@import "../../sass/variables";

.job-offer-promotion-video-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: lighten($navbarMenuColorPrimary, $amount: 60);
  .job-offer-promotion-video-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    margin: 4rem 0;
    .jopv-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }
    .jopv-content-container {
      display: flex;
      align-items: center;
      column-gap: 4rem;
    }
    .jopv-video-container {
      width: 25rem;
      height: auto;
    }
    .jopv-video-txt {
      color: $navbarTxtColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      font-size: 1.1rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .job-offer-promotion-video-subcontainer {
    width: 90% !important;
    .jopv-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .jopv-content-container {
      flex-direction: column;
      row-gap: 3rem;
    }
    .jopv-video-container {
      width: 90% !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .job-offer-promotion-video-subcontainer {
    width: 80% !important;
    .jopv-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .jopv-content-container {
      flex-direction: column;
      row-gap: 3rem;
    }
    .jopv-video-container {
      width: 90% !important;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .job-offer-promotion-video-subcontainer {
    width: 90% !important;
    .jopv-title {
      text-align: center;
    }
    .jopv-content-container {
      flex-direction: column;
      row-gap: 3rem;
    }
    .jopv-video-container {
      width: auto !important;
      height: 50rem !important;
    }
  }
}