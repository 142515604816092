@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-page-banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.product-page-banner-section .ppgb-img-banner {
  height: 560px;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-page-banner-section .ppb-form-container {
  position: absolute;
  top: 25%;
  right: 5%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .ppgb-img-banner {
    height: 272px !important;
  }
  .ppb-form-container {
    top: 2% !important;
    right: 0 !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .ppgb-img-banner {
    height: 272px !important;
  }
  .ppb-form-container {
    top: 2% !important;
    right: 10% !important;
  }
}/*# sourceMappingURL=ProductPageBanner.css.map */