@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.insurance-company-grid-section {
  width: 100%;
  background-color: #0089d1;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.insurance-company-grid-section .insurance-company-grid-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
}
.insurance-company-grid-section .insurance-company-grid-subcontainer .icg-title {
  font-size: 2rem;
  font-weight: 400;
  color: #ffffff;
}
.insurance-company-grid-section .insurance-company-grid-subcontainer .icg-grid-component-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12rem;
}
.insurance-company-grid-section .insurance-company-grid-subcontainer .icg-grid-component-mobile-container {
  display: none;
  min-height: 20rem;
}
.insurance-company-grid-section .section-skew {
  -webkit-clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
          clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
  width: 100%;
  height: 36rem;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid #ffffff;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .insurance-company-grid-subcontainer {
    width: 100% !important;
    margin-top: 2rem !important;
  }
  .insurance-company-grid-subcontainer .icg-title {
    font-size: 1.5rem !important;
    text-align: center !important;
  }
  .insurance-company-grid-subcontainer .icg-grid-component-container {
    display: none !important;
  }
  .insurance-company-grid-subcontainer .icg-grid-component-mobile-container {
    display: unset !important;
  }
  .section-skew {
    bottom: -1px !important;
  }
}/*# sourceMappingURL=InsuranceCompanyGridSection.css.map */