@import "../../sass/variables";

.job-offer-advice-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $mainColorPrimary;
  .job-offer-advice-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 2rem 0;
    color: $basicColorPrimary;
    row-gap: 1rem;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .job-offer-advice-subcontainer {
    text-align: center;
    width: 90% !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .job-offer-advice-subcontainer {
    text-align: center;
    width: 80% !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .job-offer-advice-subcontainer {
    text-align: center;
    width: 90% !important;
  }
}