@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.company-home-first-section {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.company-home-first-section .company-home-first-subcontainer {
  position: absolute;
  width: 1024px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15rem;
  row-gap: 38rem;
}
.company-home-first-section .company-home-first-subcontainer .chf-first-subsection {
  width: 100%;
}
.company-home-first-section .company-home-first-subcontainer .chf-fs-subcontainer {
  max-width: 35rem;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-align: justify;
}
.company-home-first-section .company-home-first-subcontainer .chf-second-subsection {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.company-home-first-section .company-home-first-subcontainer .right-pos {
  margin-right: 5%;
}
.company-home-first-section .company-home-first-subcontainer .chf-fs-title {
  font-weight: 400;
  font-size: 2rem;
  color: #55595c;
}
.company-home-first-section .company-home-first-subcontainer .chf-fs-content-txt {
  color: #55595c;
}
.company-home-first-section .company-home-first-subcontainer .white {
  color: #ffffff;
}
.company-home-first-section .company-home-first-subcontainer .chf-fs-btn-container {
  width: 100%;
}
.company-home-first-section .company-home-first-subcontainer .chf-fs-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  border: 1px solid #0089d1;
  width: 8rem;
  padding: 0.25rem 0;
  border-radius: 1rem;
  align-items: center;
  background-color: #0089d1;
  text-decoration: none;
  color: #ffffff;
}
.company-home-first-section .company-home-first-subcontainer .chf-fs-btn:hover {
  color: #0089d1;
  background-color: #ffffff;
}
.company-home-first-section .company-home-first-subcontainer .chf-fs-btn-icon {
  width: 0.8rem;
  height: auto;
}
.company-home-first-section .chf-img-container {
  width: 1920px;
  margin: 0 auto;
}
.company-home-first-section .chf-img-container .chf-img-bg {
  width: auto;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .company-home-first-subcontainer {
    width: 90% !important;
    row-gap: 30rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .company-home-first-subcontainer {
    width: 80% !important;
    row-gap: 30rem !important;
  }
}/*# sourceMappingURL=CompanyHomeFirstSection.css.map */