@import "../../sass/variables";

.product-video-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .product-video-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    margin: 4rem 2%;
    .pv-title {
      font-size: 2rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
    }
    .pv-content-container {
      align-self: flex-start;
      color: $navbarMenuColorPrimary;
    }
    .pv-video {
      width: 40rem;
      height: auto;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-video-subcontainer {
    width: 100% !important;
    .pv-title {
      font-size: 1.5rem !important;
    }
    .pv-video {
      width: 100% !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-video-subcontainer {
    width: 80% !important;
    .pv-title {
      font-size: 1.5rem !important;
    }
    .pv-video {
      width: 100% !important;
    }
  }
}
