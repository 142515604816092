@import "../../sass/variables";

.product-grid-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .product-grid-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;
    margin: 4rem 2%;
    .pg-title {
      font-size: 2rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
    }
    .pg-grid-container {
      display: grid;
      column-gap: 2rem;
      row-gap: 2rem;
      align-items: flex-end;
    }
    .pg-two-col {
      grid-template-columns: 1fr 1fr;
    }
    .pg-three-col {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .pg-four-col {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .pgg-img {
      width: auto;
      height: 14rem;
    }
    .pgg-subtitle {
      text-align: center;
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 1.2rem;
      margin-top: 1rem;
    }
    .pgg-small-subtitle {
      text-align: center;
      color: $navbarMenuColorPrimary;
      font-size: 0.7rem;
    }
    .pgg-txt-container {
      color: $navbarMenuColorPrimary;
      margin-top: 1rem;
    }
    .pgg-txt-list-container {
      color: $navbarMenuColorPrimary;
      margin-top: 1rem;
    }
    .pgg-btn-container {
      text-align: center;
      margin-top: 1rem;
    }
    .pgg-btn {
      background-color: #009add;
      border: 1px solid #009add;
      color: white;
      padding: 0.5rem 1.5rem;
      border-radius: 1rem;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background-color: white;
        color: #009add;
      }
    }
    .pg-grid-container-mobile {
      min-height: 45rem;
      display: none;
    }
    .pg-grid-container-tablet {
      display: none;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .pg-grid-container {
    display: none !important;
  }
  .pg-title {
    font-size: 1.5rem !important;
  }
  .pg-grid-container-mobile {
    display: unset !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-grid-subcontainer {
    width: 80% !important;
    .pg-grid-container {
      display: none !important;
    }
    .pg-title {
      font-size: 1.5rem !important;
    }
    .pg-grid-container-mobile {
      display: unset !important;
      min-height: 100% !important;
    }
  }
}
