@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.counter-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  min-height: 14rem;
}
.counter-container .img-counter {
  width: 7rem;
  height: 100%;
}
.counter-container .nums-container {
  display: flex;
  color: #0089d1;
}
.counter-container .nums-container .counter-txt {
  font-weight: 500;
  font-size: 2.8rem;
}
.counter-container .counter-subtitle {
  color: #ffffff;
  font-size: 1.1rem;
  text-align: center;
}
.counter-container .counter-subtitle {
  color: #606163;
  font-size: 0.8rem;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .nums-container {
    font-size: 1rem !important;
  }
  .counter-subtitle {
    font-size: 0.5rem !important;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .nums-container {
    font-size: 1.1rem !important;
  }
  .counter-subtitle {
    font-size: 0.6rem !important;
  }
}/*# sourceMappingURL=CounterComponent.css.map */