@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.product-detail-third-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.product-detail-third-section .pdt-small-banner {
  height: 142px;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-detail-third-section .product-detail-third-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;
  row-gap: 2rem;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-type-txt-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-ttc-title {
  align-self: flex-start;
  font-size: 2rem;
  font-weight: 500;
  color: #474849;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-ttc-txt {
  color: #2e2e2f;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-ttc-list-elm {
  color: #2e2e2f;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-ttc-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-type-imgs-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  row-gap: 2rem;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-tic-three-col {
  grid-template-columns: 1fr 1fr 1fr;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-tic-four-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-tic-elm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-tic-e-img {
  width: 8rem;
  height: auto;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-tic-e-title {
  font-weight: 500;
  color: #606163;
  text-align: center;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-advice-txt {
  color: #606163;
}
.product-detail-third-section .product-detail-third-subcontainer .pdt-type-imgs-content-container-mobile {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .product-detail-third-subcontainer {
    width: 90% !important;
  }
  .product-detail-third-subcontainer .pdt-type-imgs-content-container {
    display: none !important;
  }
  .product-detail-third-subcontainer .pdt-type-imgs-content-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-third-subcontainer {
    width: 80% !important;
  }
  .product-detail-third-subcontainer .pdt-type-imgs-content-container {
    display: none !important;
  }
  .product-detail-third-subcontainer .pdt-type-imgs-content-container-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .product-detail-third-subcontainer {
    width: 80% !important;
  }
  .product-detail-third-subcontainer .pdt-type-imgs-content-container {
    display: none !important;
  }
  .product-detail-third-subcontainer .pdt-type-imgs-content-container-mobile {
    display: unset !important;
  }
}/*# sourceMappingURL=ProductDetailThirdSection.css.map */