@import "../../sass/variables";

.job-offer-form-container {
  width: 100%;
  position: absolute;
  top: 10rem;
  display: flex;
  justify-content: center;
  .job-offer-form-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: flex-end;
    .jof-form-container {
      width: 20rem;
      background-color: $basicColorPrimary;
      border: 1px solid $mainColorPrimary;
      border-radius: 1rem;
    }
    .jof-form-subcontainer {
      margin: 1.5rem 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .jof-form-title {
      color: $mainColorPrimary;
      font-weight: 400;
      font-size: 1.75rem;
    }
    .jof-form-label {
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
      color: darken($navbarTxtColorPrimary, $amount: 20);
    }
    .jof-form-input {
      border: 1px solid $mainColorPrimary;
      resize: none;
      padding: 0.5rem 1rem;
      outline: none;
      border-radius: 0.5rem;
    }
    .error-form {
      border: 1px solid red;
    }
    .jof-form-btn {
      padding: 0.5rem 1.5rem;
      background-color: $mainColorPrimary;
      border: 1px solid $mainColorPrimary;
      color: $basicColorPrimary;
      border-radius: 0.5rem;
      outline: none;
      cursor: pointer;
      &:hover {
        background-color: $basicColorPrimary;
        color: $adviceSectionBg;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .job-offer-form-container {
    position: unset !important;
    .job-offer-form-subcontainer {
      width: 90% !important;
      justify-content: center !important;
    }
    .jof-form-container {
      width: 100% !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .job-offer-form-container {
    position: unset !important;
    .job-offer-form-subcontainer {
      width: 80% !important;
      justify-content: center !important;
    }
    .jof-form-container {
      width: 100% !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .job-offer-form-container {
    position: unset !important;
    .job-offer-form-subcontainer {
      width: 90% !important;
      justify-content: center !important;
    }
    .jof-form-container {
      width: 50% !important;
    }
  }
}
