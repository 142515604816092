@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-advice-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #0089d1;
}
.job-offer-advice-section .job-offer-advice-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 2rem 0;
  color: #ffffff;
  row-gap: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .job-offer-advice-subcontainer {
    text-align: center;
    width: 90% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .job-offer-advice-subcontainer {
    text-align: center;
    width: 80% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job-offer-advice-subcontainer {
    text-align: center;
    width: 90% !important;
  }
}/*# sourceMappingURL=JobOfferAdviceSection.css.map */