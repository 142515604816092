@import "../../sass/variables";

.product-detail-first-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .product-detail-first-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    row-gap: 3rem;
    .pdf-first-info-container {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
    }
    .pdf-fi-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .pdf-fi-content {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pdf-fi-list-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pdf-second-info-container {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      width: 100%;
    }
    .pdf-si-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 1.8rem;
      font-weight: 500;
    }
    .pdf-si-btns-container {
      display: grid;
      justify-content: space-between;
      column-gap: 3rem;
      align-items: flex-start;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 3rem;
    }
    .four-col {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .pdf-third-info-container {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
    }
    .pdf-ti-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 1.8rem;
      font-weight: 500;
    }
    .pdf-ti-list-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pdf-si-btns-container-mobile {
      display: none;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-detail-first-subcontainer {
    width: 90% !important;
    .pdf-fi-title {
      font-size: 1.5rem !important;
    }
    .pdf-si-btns-container {
      display: none !important;
    }
    .pdf-si-title {
      font-size: 1.5rem !important;
    }
    .pdf-ti-title {
      font-size: 1.5rem !important;
    }
    .pdf-si-btns-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-detail-first-subcontainer {
    width: 80% !important;
    .pdf-fi-title {
      font-size: 1.5rem !important;
    }
    .pdf-si-btns-container {
      display: none !important;
    }
    .pdf-si-title {
      font-size: 1.5rem !important;
    }
    .pdf-ti-title {
      font-size: 1.5rem !important;
    }
    .pdf-si-btns-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .product-detail-first-subcontainer {
    width: 80% !important;
    .pdf-fi-title {
      font-size: 1.5rem !important;
    }
    .pdf-si-btns-container {
      display: none !important;
    }
    .pdf-si-title {
      font-size: 1.5rem !important;
    }
    .pdf-ti-title {
      font-size: 1.5rem !important;
    }
    .pdf-si-btns-container-mobile {
      display: unset !important;
    }
  }
}
