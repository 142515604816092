@import "../../sass/variables";

.about-us-culture-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .about-us-culture-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
    row-gap: 2rem;
    .auce-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .auce-content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 4rem;
    }
    .auce-icons-container {
      display: flex;
      align-items: center;
      column-gap: 2rem;
    }
    .auce-i-img {
      width: 6rem;
      height: auto;
    }
    .auce-icon-info-container {
      border: 1px solid $navbarMenuColorPrimary;
      border-radius: 1rem;
      max-width: 40rem;
      height: 0rem;
      opacity: 0;
      transition: 1s ease;
      //   display: none;
    }
    .show-icon-info {
      height: 100%;
      opacity: 1;
      //   display: unset;
    }
    .cursor {
      cursor: pointer;
    }
    .auce-ii-subcontainer {
      margin: 1rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .auce-ii-title {
      font-size: 1.1rem;
      text-align: center;
    }
    .auce-download-brochure-container {
      margin-top: 4rem;
    }
    .auce-db-btn {
      background-color: $mainColorPrimary;
      border: 1px solid $mainColorPrimary;
      color: $basicColorPrimary;
      text-decoration: none;
      padding: 0.5rem 1.5rem;
      border-radius: 1rem;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .auce-img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
    .auce-img-txt-container {
      border: 1px solid $navbarMenuColorPrimary;
      border-radius: 1rem;
      width: 90%;
    }
    .auce-img-txt {
      margin: 1rem;
      color: darken($navbarMenuColorPrimary, $amount: 10);
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .auce-content-container-mobile {
      display: none;
      width: 100%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .about-us-culture-subcontainer {
    width: 90% !important;
    .auce-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .auce-content-container {
      display: none !important;
    }
    .auce-content-container-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .about-us-culture-subcontainer {
    width: 90% !important;
    .auce-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .auce-content-container {
      display: none !important;
    }
    .auce-content-container-mobile {
      display: unset !important;
    }
  }
}
