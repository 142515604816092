@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.insurance-grid-mobile-component-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.insurance-grid-mobile-component-container .igmc-icon-btn {
  border: 1px solid #606163;
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 1rem;
  border: 1px solid #c7c7c8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: gray;
}
.insurance-grid-mobile-component-container .igmc-icon-btn:hover {
  border-color: #0089d1;
  background-color: #0089d1;
  transition: 0.5s ease 0s;
  color: white;
}
.insurance-grid-mobile-component-container .igmc-icon-btn .igmc-icon {
  width: 4rem;
  height: auto;
}
.insurance-grid-mobile-component-container .igmc-icon-btn .igmc-subtitle {
  font-size: 0.8rem;
}/*# sourceMappingURL=InsuranceGridMobileComponent.css.map */