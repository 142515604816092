@import "../../sass/variables";

.company-home-third-section {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  //   top: -22rem;
  background-color: $adviceSectionBg;
  .company-home-third-subcontainer {
    width: 1024px;
    height: 100%;
    display: flex;
    margin-top: 3rem;
    column-gap: 3rem;
    .cht-first-subsection {
      width: 100%;
    }
    .cht-subcontainer {
      max-width: 35rem;
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      text-align: justify;
      justify-content: center;
      margin-bottom: 15%;
    }
    .cht-second-subsection {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    .right-pos {
      margin-right: 5%;
    }
    .cht-title {
      font-weight: 400;
      font-size: 2rem;
      color: $navbarMenuColorPrimary;
    }
    .cht-content-txt {
      color: $navbarMenuColorPrimary;
    }
    .white {
      color: $basicColorPrimary;
    }
    .cht-btn-container {
      width: 100%;
    }
    .cht-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      border: 1px solid $mainColorPrimary;
      width: 8rem;
      padding: 0.25rem 0;
      border-radius: 1rem;
      align-items: center;
      background-color: $mainColorPrimary;
      text-decoration: none;
      color: $basicColorPrimary;
      &:hover {
        color: $mainColorPrimary;
        background-color: $basicColorPrimary;
      }
    }
    .cht-btn-icon {
      width: 0.8rem;
      height: auto;
    }
    .cht-deco-img {
      height: 40rem;
      width: auto;
    }
  }
  .cht-img-container {
    width: 1920px;
    margin: 0 auto;
    .cht-img-bg {
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }
  .skew-bottom {
    clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
    background-color: $basicColorPrimary;
    width: 100%;
    height: 36rem;
    position: absolute;
    bottom: 0;
  }
}

//Responsive Mobile Small - Mobile Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .company-home-third-subcontainer {
    width: 90% !important;
    display: unset !important;
    margin-top: 0 !important;
    height: 27rem !important;
    .cht-deco-img {
      display: none !important;
    }
  }
  .skew-bottom {
    bottom: -1px !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .company-home-third-subcontainer {
    width: 80% !important;
    display: unset !important;
    margin-top: 0 !important;
    height: 20rem !important;
    .cht-deco-img {
      display: none !important;
    }
  }
  .skew-bottom {
    bottom: -1px !important;
  }
}