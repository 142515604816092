@import "../../../sass/variables";


.insurance-people-grid-section {
    width: 100%;
    display: flex;
    justify-content: center;
    .insurance-people-grid-subcontainer {
        width: 1024px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1.5rem;
        margin: 6rem 0 4rem 0;
        .ipg-title {
            font-size: 2rem;
            font-weight: 500;
            color: darken($navbarTxtColorPrimary, $amount: 10);
        }
        .ipg-text {
            font-size: 1rem;
            color: lighten($navbarTxtColorPrimary, $amount: 10);
        }
        .ipg-insurance-btn-container {
            margin-top: 3rem;
        }
    }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .insurance-people-grid-subcontainer {
        width: 100% !important;
        .ipg-title {
            text-align: center;
            font-size: 1.5rem !important;
        }
        .ipg-text {
            text-align: center;
        }
        .ipg-insurance-btn-container {
            display: none !important;
        }
    }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
    .insurance-people-grid-subcontainer {
        width: 100% !important;
        .ipg-title {
            text-align: center;
            font-size: 1.5rem !important;
        }
        .ipg-text {
            text-align: center;
        }
        .ipg-insurance-btn-container {
            display: none !important;
        }
    }
}

//Responsive Tablet Small >
@media screen and (min-width: $tabletSm) {
    .ipg-insurance-mobile-btn-container {
        display: none !important;
    }
}