@import "../../sass/variables";

.navbar-home-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  .navbar-home-subcontainer {
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    padding: 0 1rem;
    z-index: 99;
    .logo-container {
      width: 8rem;
    }
    .logo {
      width: 100%;
      height: 100%;
    }
    .nh-menus-container {
      display: flex;
      column-gap: 1.5rem;
      align-items: center;
    }
    .nh-menu {
      color: $navbarTxtColorPrimary;
      text-decoration: none;
      &:hover {
        color: $mainColorPrimary;
        border-bottom: 2px solid $mainColorPrimary;
      }
    }
    .job-offer-btn {
      background-color: $navbarBtnColorPrimary;
      padding: 0.2rem 1rem;
      border-radius: 1rem;
      &:hover {
        color: $navbarBtnColorPrimary;
        background-color: $basicColorPrimary;
        border: 1px solid $navbarBtnColorPrimary;
      }
    }
    .ham-menu-container {
      display: none;
    }
    .ham-menu-icon {
      width: 1rem;
    }
    .menu-options-container {
      display: none;
      position: absolute;
      top: 5rem;
      width: 100%;
      background-color: $basicColorPrimary;
      left: -40rem;
      .anchor-container {
        padding: 1rem 0 1rem 2rem;
        &:hover {
          color: $basicColorPrimary;
          background-color: $navbarMenuColorPrimary;
          .anchor {
            color: $basicColorPrimary;
          }
        }
      }
      .anchor {
        color: $basicColorSecondary;
        &:link {
          color: $basicColorSecondary;
          text-decoration: none;
        }
        &:visited {
          color: $basicColorSecondary;
        }
        &:active {
          color: $basicColorSecondary;
        }
      }
    }
    .open-menu {
      @keyframes openMenuNv {
        0% {
          left: -70rem;
        }
        100% {
          left: 0rem;
        }
      }
      animation-name: openMenuNv;
      animation-duration: 1s;
      left: 0rem;
    }
    .close-menu {
      @keyframes closeMenuNv {
        0% {
          left: 0rem;
        }
        100% {
          left: -70rem;
        }
      }
      animation-name: closeMenuNv;
      animation-duration: 1s;
      left: -70rem;
    }
    .ham-menu-icon {
      width: 1.5rem;
      height: 100%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .logo-container {
    width: 6rem !important;
  }
  .nh-menus-container {
    display: none !important;
  }
  .ham-menu-container {
    display: unset !important;
  }
  .menu-options-container {
    display: unset !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .logo-container {
    width: 6rem !important;
  }
  .nh-menus-container {
    display: none !important;
  }
  .ham-menu-container {
    display: unset !important;
  }
  .menu-options-container {
    display: unset !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .navbar-home-subcontainer {
    padding: 0 10% !important;
  }
  .nh-menus-container {
    display: none !important;
  }
  .ham-menu-container {
    display: unset !important;
  }
  .menu-options-container {
    display: unset !important;
  }
}
