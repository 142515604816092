@import "../../sass/variables";

.static-banner-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  .sb-img {
    width: 1920px;
    object-fit: cover;
  }
}
