@import "../../../sass/variables";

.prizes-carousel-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
  .prizes-carousel-subcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3rem;
    margin: 4rem 0;
    .pc-carousel-container {
      width: 100%;
    }
    .pc-title {
      color: #4a4a4a;
      font-size: 1.3rem;
      text-align: center;
    }
    .pc-carousel-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .pcci-subtitle {
      color: #4a4a4a;
      font-size: 1.1rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .pc-title {
    font-size: 1rem !important;
  }
  .pcci-subtitle {
    text-align: center;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .pc-title {
    font-size: 1rem !important;
  }
  .pcci-subtitle {
    text-align: center;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  // .pc-title {
  //   font-size: 1rem !important;
  // }
  // .pcci-subtitle {
  //   text-align: center;
  // }
}