@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.insurance-partner-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.insurance-partner-section .insurance-partner-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
  row-gap: 4rem;
}
.insurance-partner-section .insurance-partner-subcontainer .ip-carousel-container {
  width: 100%;
}
.insurance-partner-section .insurance-partner-subcontainer .ip-title {
  color: #4a4a4a;
  font-size: 1.5rem;
  text-align: center;
}
.insurance-partner-section .insurance-partner-subcontainer .ipc-item-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.insurance-partner-section .insurance-partner-subcontainer .ipc-insurance-logo {
  height: 3rem;
  max-width: 5rem;
}/*# sourceMappingURL=InsurancePartnerSection.css.map */