@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.review-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
}
.review-section .r-stars-subsection {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
  padding: 2rem 0;
}
.review-section .r-stars-container {
  width: 650px;
  position: relative;
  display: flex;
  justify-content: center;
}
.review-section .r-stars-container .r-stars-img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.review-section .r-stars-container .stars-bg-color {
  background-color: #0097eb;
  height: 7.7rem;
  position: absolute;
  top: 0;
  left: 0;
}
.review-section .r-stars-txt-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
  padding-bottom: 2rem;
}
.review-section .r-stars-txt-container .r-stars-txt {
  width: 1024px;
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  color: #7a7a7a;
}
.review-section .review-subcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.review-section .review-subcontainer .r-cards-container {
  width: 1024px;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .r-stars-container {
    width: 100% !important;
    margin: 0 1rem;
  }
  .r-stars-subsection {
    height: 4rem !important;
  }
  .r-stars-subsection .r-stars-img {
    height: 3.5rem !important;
    top: -0.05rem !important;
  }
  .r-stars-subsection .stars-bg-color {
    max-height: 3.4rem !important;
  }
  .r-stars-txt {
    margin: 0 1rem;
    text-align: center;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .r-stars-container {
    width: 100% !important;
    margin: 0 1rem;
  }
  .r-stars-subsection {
    height: 4rem !important;
  }
  .r-stars-subsection .r-stars-img {
    height: 5.5rem !important;
    top: -0.05rem !important;
  }
  .r-stars-subsection .stars-bg-color {
    max-height: 5.4rem !important;
  }
  .r-stars-txt {
    margin: 0 1rem;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .r-stars-container {
    width: 100% !important;
    margin: 0 1rem;
  }
  .r-stars-subsection {
    height: 7rem !important;
  }
  .r-stars-subsection .r-stars-img {
    height: 7.5rem !important;
    top: -0.05rem !important;
  }
  .r-stars-subsection .stars-bg-color {
    max-height: 7.4rem !important;
  }
  .r-stars-txt {
    margin: 0 1rem;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .stars-bg-color {
    top: 0.1rem !important;
  }
}/*# sourceMappingURL=ReviewSection.css.map */