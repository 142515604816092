@import "../../sass/variables";

.product-detail-second-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: lighten($navbarMenuColorPrimary, $amount: 60);
  .product-detail-second-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0;
    row-gap: 2rem;
    .pds-more-info-title {
      align-self: flex-start;
      font-size: 2rem;
      font-weight: 500;
      color: darken($navbarTxtColorPrimary, $amount: 10);
    }
    .pds-more-info-content-container {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      color: $navbarTxtColorPrimary;
    }
    .pds-info-title {
      font-size: 2rem;
      font-weight: 500;
      color: darken($navbarTxtColorPrimary, $amount: 10);
    }
    .pds-i-content-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
    .pds-i-content-elm-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 1.5rem;
    }
    .pds-i-ce-img {
      width: 8rem;
      height: auto;
    }
    .pds-i-ce-title {
      font-weight: 500;
      color: darken($navbarTxtColorPrimary, $amount: 10);
      width: 15rem;
    }
    .pds-btn {
      background-color: $mainColorPrimary;
      border: 1px solid $mainColorPrimary;
      color: $basicColorPrimary;
      padding: 0.5rem 1.5rem;
      border-radius: 1.5rem;
      text-decoration: none;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorPrimary;
      }
    }
    .pds-i-content-container-mobile {
      display: none;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .product-detail-second-subcontainer {
    width: 90% !important;
    .pds-info-title {
      font-size: 1.5rem !important;
    }
    .pds-i-content-container {
      display: none !important;
    }
    .pds-i-content-container-mobile {
      display: unset !important;
      width: 100%;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .product-detail-second-subcontainer {
    width: 80% !important;
    .pds-info-title {
      font-size: 1.5rem !important;
    }
    .pds-i-content-container {
      display: none !important;
    }
    .pds-i-content-container-mobile {
      display: unset !important;
      width: 100%;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .product-detail-second-subcontainer {
    width: 90% !important;
    .pds-info-title {
      font-size: 1.5rem !important;
    }
  }
}
