@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-page-banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.job-offer-page-banner-section .jopb-banner-img {
  height: 15rem;
  width: auto;
  filter: saturate(0.5);
  -o-object-fit: cover;
     object-fit: cover;
}
.job-offer-page-banner-section .job-offer-page-banner-subcontainer {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
}
.job-offer-page-banner-section .job-offer-page-banner-subcontainer .jopb-job-info-container {
  width: 1024px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.job-offer-page-banner-section .job-offer-page-banner-subcontainer .jopb-ji-title {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 500;
}
.job-offer-page-banner-section .job-offer-page-banner-subcontainer .jopb-ji-more-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
}
.job-offer-page-banner-section .job-offer-page-banner-subcontainer .jopb-ji-mi-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.job-offer-page-banner-section .job-offer-page-banner-subcontainer .jopb-ji-mi-icon {
  height: 1rem;
  width: auto;
}
.job-offer-page-banner-section .job-offer-page-banner-subcontainer .jopb-ji-mi-txt {
  color: #ffffff;
  font-size: 1.1rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .jopb-job-info-container {
    width: 90% !important;
  }
  .jopb-job-info-container .jopb-ji-title {
    font-size: 1.25rem !important;
  }
  .jopb-job-info-container .jopb-ji-mi-txt {
    font-size: 0.8rem !important;
  }
  .jopb-job-info-container .jopb-ji-more-info-container {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .jopb-job-info-container {
    width: 80% !important;
  }
  .jopb-job-info-container .jopb-ji-mi-txt {
    font-size: 0.8rem !important;
  }
  .jopb-job-info-container .jopb-ji-more-info-container {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .jopb-job-info-container {
    width: 90% !important;
  }
}/*# sourceMappingURL=JobOfferPageBannerSection.css.map */