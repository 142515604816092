@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.first-video-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.first-video-section .first-video-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
  margin: 4rem 0;
}
.first-video-section .first-video-subcontainer .fv-title {
  width: 50%;
  color: #4a4a4a;
  font-size: 1.8rem;
  text-align: center;
}
.first-video-section .first-video-subcontainer .fv-video {
  height: 100%;
  width: auto;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .fv-title {
    font-size: 1.5rem !important;
  }
  .fv-media-container {
    display: flex;
    justify-content: center;
  }
  .fv-media-container .fv-video {
    width: 90% !important;
    height: auto !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .fv-title {
    font-size: 1.5rem !important;
  }
  .fv-media-container {
    display: flex;
    justify-content: center;
  }
  .fv-media-container .fv-video {
    width: 90% !important;
    height: auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .first-video-subcontainer {
    margin: 4rem 10% !important;
  }
  .fv-title {
    font-size: 1.5rem !important;
  }
  .fv-media-container {
    display: flex;
    justify-content: center;
  }
  .fv-media-container .fv-video {
    width: 90% !important;
    height: auto !important;
  }
}/*# sourceMappingURL=FirstVideoSection.css.map */