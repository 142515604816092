@import "../../sass/variables";

.product-small-banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .psb-img {
    height: 300px;
    width: auto;
    object-fit: cover;
  }
  .product-small-banner-subcontainer {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100%;
    .psb-grid-container {
      width: 1024px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 5rem;
    }
    .psb-elm-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 2rem;
    }
    .psb-title {
      color: darken($navbarTxtColorPrimary, $amount: 15);
    }
    .psb-btn {
      background-color: #009add;
      border: 1px solid #009add;
      color: white;
      padding: 0.5rem 1.5rem;
      border-radius: 1rem;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background-color: white;
        color: #009add;
      }
    }
  }
}

//Responsive Mobile Small - Mobile Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .psb-grid-container {
    flex-direction: column;
    width: 90% !important;
    row-gap: 1rem;
    .psb-elm-container {
        row-gap: 1rem !important;
    }
  }
}
