@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.banner-with-skew-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.banner-with-skew-section .banner-with-skew-subcontainer {
  width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.banner-with-skew-section .banner-with-skew-subcontainer .text-slide {
  width: 100%;
  position: relative;
}
.banner-with-skew-section .banner-with-skew-subcontainer .ts-info-content {
  position: absolute;
  top: 30%;
  left: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.banner-with-skew-section .banner-with-skew-subcontainer .ts-info-title {
  font-size: 4rem;
  color: #ffffff;
  font-weight: 500;
}
.banner-with-skew-section .banner-with-skew-subcontainer .ts-info-subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0.25rem 0.25rem;
  text-align: center;
}
.banner-with-skew-section .banner-with-skew-subcontainer .ts-info-btn {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ffffff;
  font-size: 1.25rem;
  width: 10rem;
  padding: 0.25rem 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.banner-with-skew-section .banner-with-skew-subcontainer .ts-info-btn:hover {
  background-color: transparent;
  color: #ffffff;
}
.banner-with-skew-section .bws-skew-elm {
  -webkit-clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
          clip-path: polygon(0 92%, 100% 77%, 100% 100%, 0% 100%);
  background-color: #0089d1;
  width: 100%;
  height: 36rem;
  position: absolute;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .bws-skew-elm {
    height: 18rem !important;
  }
}/*# sourceMappingURL=BannerWithSkewComponet.css.map */