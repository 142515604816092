@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.salesforce-bg-container {
  width: 100vw;
  height: 100vh;
}

.sb-img {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=SalesforceBgScreen.css.map */