@import "../../../sass/variables";

.first-video-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .first-video-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;
    margin: 4rem 0;
    .fv-title {
      width: 50%;
      color: #4a4a4a;
      font-size: 1.8rem;
      text-align: center;
    }
    .fv-video {
      height: 100%;
      width: auto;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard)) {
  .fv-title {
    font-size: 1.5rem !important;
  }
  .fv-media-container {
    display: flex;
    justify-content: center;
    .fv-video {
        width: 90% !important;
        height: auto !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm)) {
  .fv-title {
    font-size: 1.5rem !important;
  }
  .fv-media-container {
    display: flex;
    justify-content: center;
    .fv-video {
        width: 90% !important;
        height: auto !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard)) {
  .first-video-subcontainer {
    margin: 4rem 10% !important;
  }
  .fv-title {
    font-size: 1.5rem !important;
  }
  .fv-media-container {
    display: flex;
    justify-content: center;
    .fv-video {
        width: 90% !important;
        height: auto !important;
    }
  }
}
